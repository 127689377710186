:root {
  --bs-white: #fff;
  --bs-gray: #606060;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #000000;
  --bs-primary-hover: #333;
  --bs-primary-disabled: #666;
  --bs-secondary: #D01F25;
  --bs-secondary-hover: #b6171c;
  --bs-secondary-disabled: #d94c51;
  --bs-accent: #6D7FEA;
  --bs-accent-hover: #6271C7;
  --bs-accent-disabled: #AFB8ED;
  --bs-success: #6BBC2E;
  --bs-success-hover: #60AA28;
  --bs-success-disabled: #9CD86F;
  --bs-info: #9CA6D6;
  --bs-warning: #FDEB0F;
  --bs-warning-hover: #F4DE10;
  --bs-warning-disabled: #FFF48D;
  --bs-danger: #F5475B;
  --bs-danger-hover: #D52E41;
  --bs-danger-disabled: #F7939F;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-blue: #323B6B;
  --bs-primary-rgb: 0, 0, 0;
  --bs-secondary-rgb: 208, 31, 37;
  --bs-success-rgb: 107, 188, 46;
  --bs-info-rgb: 156, 166, 214;
  --bs-warning-rgb: 253, 235, 15;
  --bs-danger-rgb: 245, 71, 91;
  --bs-light-rgb: 248, 249, 250;
  --bs-dark-rgb: 33, 37, 41;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 33, 37, 41;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-body-font-family: 'Poppins', sans-serif;
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #000000;
  --bs-body-bg: #F8F8F8;
  --bs-border-color: #DEDEDE;
}

/*html{ font-size: 12px;}*/
body {
  font-size: .8rem;
}

.body-xs {
  font-size: .512rem;
}

.body-s {
  font-size: .65rem;
}

.body-m {
  font-size: .8rem;
}

.body-l {
  font-size: 1rem;
}

.hs {
  font-size: 1.25rem;
}

.hm {
  font-size: 1.563rem;
}

.hl {
  font-size: 1.953rem;
}

.hxl {
  font-size: 2.441rem;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.text-gray {
  color: var(--bs-gray) !important;
}

.text-blue {
  color: var(--bs-blue) !important;
}

.border-top {
  border-top-color: var(--bs-border-color) !important;
}

.c-pointer {
  cursor: pointer;
}

.c-default,
button:not(:disabled).c-default {
  cursor: default;
}

.opacity-25 {
  opacity: .25;
}

.text-14 {
  font-size: .875rem;
}

.lh-18 {
  line-height: 1.125rem;
}

a {
  color: var(--bs-primary);
}

a:hover {
  color: var(--bs-primary-hover);
}

hr {
  background-color: var(--bs-border-color);
  opacity: 1;
}

.mx-n3 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.icon {
  background-repeat: no-repeat;
  background-position: center center;
}

.icon-size {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 1em;
  height: 1em;
  display: inline-block;
}

.icon-size-c {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  width: 1em;
  height: 1em;
  display: inline-block;
}

.icon-8 {
  font-size: .5rem;
}

.icon-10 {
  font-size: .675rem;
}

.icon-12 {
  font-size: .75rem;
}

.icon-14 {
  font-size: .875rem;
}

.icon-16 {
  font-size: 1rem;
}

.icon-18 {
  font-size: 1.125rem;
}

.icon-20 {
  font-size: 1.25rem;
}

.icon-32 {
  font-size: 2rem;
}

.icon-64 {
  font-size: 4rem;
}

.icon-settings-dark {
  background-image: url(../assets/svg/settings-dark.svg);
}

.icon-settings {
  background-image: url(../assets/svg/settings.svg);
}

.icon-help {
  background-image: url(../assets/svg/help.svg);
}

.icon-bell {
  background-image: url(../assets/svg/bell.svg);
}

.icon-clock {
  background-image: url(../assets/svg/clock.svg);
}

.icon-logout {
  background-image: url(../assets/svg/log-out.svg);
}

.icon-home {
  background-image: url(../assets/svg/home.svg);
}

.icon-client {
  background-image: url(../assets/svg/client.svg);
}

.icon-warehouse {
  background-image: url(../assets/svg/warehouse.svg);
}

.icon-customers {
  background-image: url(../assets/svg/clients.svg);
}

.icon-orders {
  background-image: url(../assets/svg/orders.svg);
}

.icon-offer {
  background-image: url(../assets/svg/offer.svg);
}

.icon-invoice {
  background-image: url(../assets/svg/invoice.svg);
}

.icon-set-time-yes {
  background-image: url(../assets/svg/set-time-yes.svg);
}

.icon-set-time-no {
  background-image: url(../assets/svg/set-time-no.svg);
}

.icon-send-accent {
  background-image: url(../assets/svg/send-accent.svg);
}

.icon-email {
  background-image: url(../assets/svg/email.svg);
}

.icon-email-yes {
  background-image: url(../assets/svg/email-yes.svg);
}

.icon-email-no {
  background-image: url(../assets/svg/email-no.svg);
}

.icon-reply {
  background-image: url(../assets/svg/reply.svg);
}

.icon-reply-yes {
  background-image: url(../assets/svg/reply-yes.svg);
}

.icon-reply-no {
  background-image: url(../assets/svg/reply-no.svg);
}

.icon-attachment {
  background-image: url(../assets/svg/attachment.svg);
}

.icon-clock-green {
  background-image: url(../assets/svg/clock-green.svg);
}

.icon-close {
  background-image: url(../assets/svg/close.svg);
}

.icon-close-white {
  background-image: url(../assets/svg/close-white.svg);
}

.icon-failure-red {
  background-image: url(../assets/svg/failure-red.svg);
}

.icon-success {
  background-image: url(../assets/svg/success.svg);
}

.icon-warning {
  background-image: url(../assets/svg/warning.svg);
}

.icon-info {
  background-image: url(../assets/svg/info.svg);
}

.icon-info-black {
  background-image: url(../assets/svg/info-black.svg);
}

.icon-info-white {
  background-image: url(../assets/svg/info-white.svg);
}

.icon-remove {
  background-image: url(../assets/svg/minus.svg);
}

.icon-up-down {
  background-image: url(../assets/svg/arrow-up-down.svg);
}

.icon-add {
  background-image: url(../assets/svg/plus-icon.svg);
}

.icon-add-dark {
  background-image: url(../assets/svg/add-dark.svg);
}

.icon-add-white {
  background-image: url(../assets/svg/add-new.svg);
}

.icon-check-yes {
  background-image: url(../assets/svg/check-yes.svg);
}

.icon-drag {
  background-image: url(../assets/svg/drag.svg);
}
.icon-copy {
  background-image: url(../assets/svg/copy.svg);
}

.drag-icon-pos {
  margin: 7px 0 0 10px;
  opacity: 0.6;
}

.header {
  min-height: 64px;
}

.header .navbar {
  min-height: 64px;
  background: #fff;
}

.header .container-fluid {
  padding-left: 1.125rem;
  padding-right: 1rem;
}

.header .navbar-nav .nav-item {
  margin-left: .5rem;
}

.logo {
  width: 112px;
  height: 35px;
  object-position: left;
  /* background: url(../assets/svg/logo.png) left top no-repeat; */
}

.logo img {
  width: 112px;
  height: 35px;
  object-fit: contain;
  object-position: left;
}

.logo-xl img {
  width: 198px;
  max-width: 100%;
  height: 63px;
  object-fit: contain;
  object-position: left;
}

.logo-xl {
  width: 198px;
  max-width: 100%;
  height: 63px;
  object-position: left;
  /* background: url(../assets/svg/logo-xl.png) left top no-repeat; */
  background-size: contain;
}

.header .navbar .icon {
  width: 20px;
  height: 20px;
  background-size: 20px 20px;
  position: relative;
}

.header .navbar .icon-bell {
  background-size: 19px 19px;
}

.header .navbar .icon .count {
  position: absolute;
  right: -4px;
  top: 0;
  width: 14px;
  height: 14px;
  background: var(--bs-danger);
  /*border: 1px solid #fff;*/
  color: #fff;
  font-size: 8px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.sidebar {
  position: fixed;
  left: 0;
  top: 64px;
  bottom: 0;
  width: 205px;
  padding-right: 1.125rem;
  background: #f8f8f8;
  z-index: 9;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='%23000000' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}


.company-dropdown {
  padding: .875rem 0 1rem 1rem;
  max-width: 100%;
  position: relative;
}

.company-dropdown label {
  font-size: .75rem;
  font-weight: 300;
  margin-bottom: .375rem;
}

.company-dropdown .dropdown-more .dropdown-toggle,
.company-dropdown .dropdown-more .dropdown-toggle:hover {
  font-size: .75rem;
  font-weight: 700;
  color: #000;
}

.sidebar .navbar {
  padding-top: 0;
}

.sidebar .nav-tabs-wrap {
  width: 100%;
  position: relative;
}

.sidebar .nav-tabs-wrap .tab-open-sidebar {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 36px;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
}

.sidebar .nav-tabs .nav-link {
  min-width: 48px;
  color: #000;
}

.sidebar .nav-tabs {
  border-bottom-color: #DEDEDE;
}

.sidebar .nav-tabs .nav-link.active {
  background-color: transparent;
  border-color: #DEDEDE #DEDEDE #F8F8F8;
}

.sidebar .nav-tabs .nav-link .icon {
  width: 15px;
  height: 15px;
  display: inline-block;
  vertical-align: top;
  margin-top: 2px;
}

.sidebar .tab-content {
  padding-top: 1.25rem;
}

.sidebar .navbar-nav .nav-link {
  color: #000;
  font-weight: 500;
  padding: .375rem .75rem .375rem 1.125rem;
  display: flex;
  align-items: center;
  white-space: nowrap;
  line-height: 1.25rem;
  position: relative;
}

.sidebar .navbar-nav .nav-link:before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 4px;
  height: 20px;
  background-color: var(--bs-primary);
  border-radius: 0 3px 3px 0;
  opacity: 0;
}

.sidebar .navbar-nav .nav-item-active .nav-link:before {
  opacity: 1;
}

.sidebar .navbar-nav .nav-link .icon {
  width: 16px;
  height: 16px;
  background-size: 16px 16px;
}

.sidebar .navbar-nav .nav-link .text {
  margin-left: 1rem;
}

.sidebar .navbar-nav .nav-link-toggle {
  position: relative;
}

.sidebar .navbar-nav .nav-link-toggle:after {
  content: "";
  width: 9px;
  height: 9px;
  background: url(../assets/svg/arrow-up.svg) center center no-repeat;
  background-size: 9px 9px;
  margin: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%) rotate(180deg);
}

.sidebar .navbar-nav .nav-link-toggle.nav-link-active:after,
.sidebar .navbar-nav .nav-item-active .nav-link-toggle:after {
  transform: translateY(-50%) rotate(0deg);
}

.sidebar .navbar-nav .nav-submenu {
  display: none;
  min-width: 1rem;
  background: transparent;
  border: none;
  list-style: none;
  padding-left: 0;
}

.sidebar .navbar-nav .nav-item-active .nav-submenu {
  display: block;
}

.sidebar .navbar-nav .nav-submenu li {
  margin-left: 2.5rem;
  padding: .25rem .25rem;
  line-height: 22px;
}

.sidebar .navbar-nav .nav-submenu li a {
  color: #000;
  font-weight: 300;
  padding: 0 .375rem;
  background: transparent;
  display: inline-block;
  text-decoration: none;
  border-radius: 3px;
}

.sidebar .navbar-nav .nav-submenu li a:hover,
.sidebar .navbar-nav .nav-submenu li a.active {
  color: #fff;
  font-weight: 500;
  background: var(--bs-primary);
}

/*.sidebar .navbar-nav .nav-submenu li a{ background: transparent;}*/

.sidebar-toggle {
  margin-left: auto;
  margin-right: -1.125rem;
  margin-top: .375rem;
}

.sidebar-toggle-btn {
  background: transparent;
  border: none;
  padding: .375rem 1.125rem;
  transform: scaleX(1);
}

.sidebar-toggle-btn-mobile,
.sidebar-overlay {
  display: none;
}

.sidebar,
.main,
.sidebar-toggle-btn,
.company-dropdown,
.sidebar .nav-tabs .nav-link,
.sidebar-minimize .tab-content,
.sidebar .navbar-nav .nav-link .text,
.sidebar .navbar-nav .nav-link-toggle:after,
.sidebar .navbar-nav .nav-submenu,
.sidebar .nav-tabs-wrap .tab-open-sidebar,
.sidebar-setting,
.sidebar-help {
  transition: all .4s ease;
}

.sidebar-minimize {
  width: 50px;
  padding-right: 0;
}

.sidebar-minimize .sidebar-toggle {
  margin: 0 auto;
}

.sidebar-minimize .sidebar-toggle-btn {
  transform: scaleX(-1);
}

.sidebar-minimize .nav-tabs-wrap .tab-open-sidebar {
  opacity: 1;
  visibility: visible;
}

.sidebar-minimize .company-dropdown {
  padding: 0;
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
}

.sidebar-minimize .nav-tabs {
  border: 0;
}

.sidebar-minimize .nav-tabs .nav-link {
  border: 0;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.sidebar-minimize .nav-tabs .nav-link:not(.active) {
  opacity: 0;
  padding: 0;
  width: 0;
  height: 0;
  overflow: hidden;
}

.sidebar-minimize .tab-content {
  padding-top: 0;
  padding-right: 0;
}

.sidebar-minimize .navbar-nav .nav-item {
  position: relative;
}

.sidebar-minimize .navbar-nav .nav-link .text {
  margin-left: 0;
  opacity: 0;
  width: 0;
  overflow: hidden;
}

.sidebar-minimize .navbar-nav .nav-link-toggle:after {
  opacity: 0;
}

.sidebar-minimize .navbar-nav .nav-submenu {
  opacity: 0;
  position: absolute;
  left: 100%;
  top: 1px;
}

.sidebar-minimize .navbar-nav .nav-item .nav-submenu {
  display: none;
}

.sidebar-minimize .navbar-nav .nav-item .nav-submenu:before {
  content: '';
  border-right: 6px solid var(--bs-primary);
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  position: absolute;
  left: -6px;
  top: 12px;
  display: inline-block;
}

.sidebar-minimize .navbar-nav .nav-item .nav-submenu li a {
  padding: .25rem 1rem;
  color: #fff;
  white-space: nowrap;
}

.sidebar-setting,
.sidebar-help {
  padding: 0 1.125rem;
  opacity: 1;
}

.sidebar-minimize .sidebar-setting,
.sidebar-minimize .sidebar-help {
  padding: 0;
  width: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
}

.main {
  padding-left: 50px;
}

.sidebar-minimize+.main {
  padding-left: 50px;
}

.page-header {
  padding: .875rem 0 1rem;
}

.page-header .page-title h1 {
  font-size: .875rem;
  line-height: 1.125rem;
  font-weight: 600;
}

.breadcrumb {
  margin-bottom: 0;
  line-height: 1.125rem;
}

.breadcrumb .breadcrumb-item a,
.breadcrumb .breadcrumb-item.active {
  color: #000;
  font-weight: 300;
  text-decoration: none;
}

.breadcrumb .breadcrumb-item a .icon {
  width: 12px;
  height: 12px;
  background-size: 12px 12px;
  display: inline-block;
  vertical-align: -1px;
}

.search-bar {
  padding: .875rem 1.25rem;
  margin-bottom: 1.125rem;
  background: var(--bs-secondary);
  color: #fff;
  box-shadow: 0px 0px 1px #00000029;
  border-radius: 3px;
}

.search-bar.add-icon-right {
  position: relative;
  padding-right: 2.75rem;
}

.add-icon-right .add-icon-wrap {
  position: absolute;
  bottom: 1rem;
  right: .75rem
}

/*.search-bar .dropdown-custom .dropdown-menu{ margin-top: 14px !important;}*/
.dropdown-item:focus,
.dropdown-item:hover {
  color: #fff;
  background-color: var(--bs-primary);
}

.dropdown-custom .dropdown-toggle,
.dropdown-custom .dropdown-toggle:hover {
  color: #fff;
  padding: 0;
  text-decoration: none;
  box-shadow: none;
  font-size: .8rem;
  line-height: 1rem;
}

.dropdown-custom .dropdown-toggle::after {
  display: none;
}

.dropdown-custom .dropdown-toggle .fas,
.dropdown-custom .dropdown-toggle .icon {
  background-color: var(--bs-primary);
  border-radius: 3px;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin-left: .5rem;
}

.dropdown-custom .dropdown-toggle.form-control,
.dropdown-custom .dropdown-toggle.form-control:hover {
  color: #000;
  padding: 0.375rem 0.625rem;
  text-decoration: none;
  box-shadow: none;
  font-size: .8rem;
  line-height: 1.125rem;
}

.dropdown-custom .dropdown-toggle.form-control-sm,
.dropdown-custom .dropdown-toggle.form-control-sm:hover {
  padding: 0.25rem 0.375rem;
  line-height: .875rem;
}

.dropdown-custom.show .dropdown-toggle .fa,
.dropdown-custom.show .dropdown-toggle .icon {
  background-color: #2856a7;
  border-color: #2856a7;
  color: #fff;
}

.dropdown-custom .dropdown-menu {
  border: none;
  border-radius: 3px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, .16);
  padding: .875rem .875rem;
  font-size: .8rem;
  line-height: 1rem;
  white-space: nowrap;
}

.search-bar .dropdown-custom .dropdown-menu {
  max-height: 400px;
  overflow-y: auto;
}

.dropdown-custom .dropdown-menu .form-check,
.dropdown-custom-alt .dropdown-menu .form-check {
  cursor: pointer;
  margin-bottom: 0;
}

.dropdown-custom .dropdown-menu .form-check+.form-check,
.dropdown-custom-alt .dropdown-menu .form-check+.form-check {
  margin-top: .75rem;
}

/*.dropdown-custom,.dropdown-custom-alt .dropdown-menu .form-check .form-check-input[type="checkbox"]{ margin-top: 0;}*/
.dropdown-custom .dropdown-menu .form-check .form-check-label,
.dropdown-custom-alt .dropdown-menu .form-check .form-check-label {
  font-size: .8rem;
  font-weight: 400;
  cursor: pointer;
  display: block;
}

.dropdown-custom .dropdown-menu .conc-group-main+.conc-group-main,
.dropdown-custom-alt .dropdown-menu .conc-group-main+.conc-group-main {
  margin-top: .75rem;
}

.dropdown-custom .dropdown-menu .conc-group-main>.item-label,
.dropdown-custom-alt .dropdown-menu .conc-group-main>.item-label {
  font-weight: 700;
  margin-bottom: .75rem;
}

.dropdown-custom .dropdown-menu .conc-group-sub>.item-label,
.dropdown-custom-alt .dropdown-menu .conc-group-sub>.item-label {
  font-weight: 500;
  margin-bottom: .75rem;
}

.dropdown-custom .dropdown-menu .conc-group-sub+.conc-group-sub,
.dropdown-custom-alt .dropdown-menu .conc-group-sub+.conc-group-sub {
  margin-top: .75rem;
}

.dropdown-custom .dropdown-menu .conc-group-main>.form-check+.conc-group-sub,
.dropdown-custom .dropdown-menu .conc-group-main>.conc-group-sub+.form-check,
.dropdown-custom-alt .dropdown-menu .conc-group-main>.form-check+.conc-group-sub {
  margin-top: .75rem;
}

.dropdown-custom-alt .dropdown-toggle,
.dropdown-custom-alt .dropdown-toggle:hover {
  color: #fff;
  padding: .375rem 1.625rem .375rem .625rem;
  background-color: #fff;
  border-color: var(--bs-border-color);
  color: #000;
  text-decoration: none;
  box-shadow: none;
  font-size: .8rem;
  line-height: 1.125rem;
  font-weight: 400;
  height: 32px;
  text-align: left;
  border-radius: 3px;
}

.dropdown-custom-alt .dropdown-toggle::after {
  display: none;
}

.dropdown-custom-alt .dropdown-toggle .fas {
  position: absolute;
  right: .625rem;
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
  color: var(--bs-gray);
  font-size: calc(.5rem + 1px);
  transition: all .3s ease;
}

.dropdown-custom-alt .dropdown-toggle.show .fas {
  transform: translateY(-50%) rotate(-180deg);
}

.dropdown-custom-alt .dropdown-menu {
  min-width: 6rem;
  border: none;
  border-radius: 3px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, .16);
  padding: .875rem .875rem;
  font-size: .8rem;
  line-height: 1rem;
  white-space: nowrap;
}

.search-bar .dropdown-custom-alt .dropdown-menu {
  max-height: 400px;
  overflow-y: auto;
}

.dropdown-custom-alt .dropdown-menu .dropdown-item {
  padding: .25rem .625rem;
}

.dropdown-custom-alt .dropdown-menu .dropdown-item-color {
  padding-left: 1.625rem;
  position: relative;
}

.dropdown-custom-alt .dropdown-menu .dropdown-item-color:focus,
.dropdown-custom-alt .dropdown-menu .dropdown-item-color:hover {
  background-color: var(--bs-body-bg);
  color: #000;
}

.dropdown-custom-alt .dropdown-menu .dropdown-item-color .item-color {
  width: .625rem;
  height: .625rem;
  position: absolute;
  left: .4rem;
  top: 50%;
  transform: translateY(-50%);
}

.dropdown-more .dropdown-toggle,
.dropdown-more .dropdown-toggle:hover {
  color: #fff;
  padding: 0;
  text-decoration: none;
  box-shadow: none;
  font-size: .8rem;
  line-height: 1rem;
  border: none;
}

.dropdown-more .dropdown-toggle::after {
  display: none;
}

.dropdown-more .dropdown-toggle .fas {
  color: #000;
  display: inline-block;
  font-size: 12px;
  width: 20px;
  height: 20px;
  line-height: 20px;
}

.dropdown-more-icon-sm .dropdown-toggle .fas {
  width: 16px;
  height: 16px;
  line-height: 16px;
}

.dropdown-more .dropdown-menu {
  min-width: 6.5rem;
  background: var(--bs-primary);
  border: none;
  padding: .25rem 0;
  font-size: .8rem;
  line-height: 1rem;
  white-space: nowrap;
  border-radius: 3px;
  box-shadow: 0 0 1px rgba(0, 0, 0, .16);
}

.dropdown-more .dropdown-menu .dropdown-item {
  color: #fff;
  font-family: 'Roboto', sans-serif;
  padding: .25rem .75rem;
}

.dropdown-more .dropdown-menu .dropdown-item:focus,
.dropdown-more .dropdown-menu .dropdown-item:hover,
.dropdown-more .dropdown-menu .dropdown-item.active,
.dropdown-more .dropdown-menu .dropdown-item:active {
  color: #fff;
  background-color: var(--bs-primary-hover);
}

.dropdown-more .dropdown-menu .form-label {
  color: #fff;
}

.dropdown-menu .form-control {
  height: 24px;
  padding: .125rem .5rem;
}

.dropdown-form-control .dropdown-toggle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
}

.dropdown-form-control .dropdown-toggle:after {
  display: none;
}

.dropdown-form-control .dropdown-menu {
  font-size: .8rem;
  width: 100%;
}

.col-form-label {
  font-weight: 500;
  line-height: 18px;
}

.form-label {
  font-weight: 500;
  color: #000;
  margin-bottom: .25rem;
}

.form-control {
  padding: 0.375rem 0.625rem;
  color: #000;
  border-color: var(--bs-border-color);
  font-size: .8rem;
  line-height: 1.125rem;
  font-weight: 400;
  height: 32px;
  border-radius: 3px;
}

.form-control-plaintext {
  padding: 0.375rem 0;
  color: #000;
  font-size: .8rem;
  line-height: 1.125rem;
  font-weight: 400;
}

.form-lg,
.form-lg .form-control {
  font-size: 1rem;
}

.form-select {
  padding: 0.375rem 1.75rem 0.375rem 0.625rem;
  height: 32px;
  -moz-padding-start: calc(0.625rem - 3px);
  font-size: .8rem;
  line-height: 1.125rem;
  font-weight: 400;
  color: #000;
  border-color: var(--bs-border-color);
  background-position: right 0.625rem center;
  background-size: 14px 12px;
  border-radius: 3px;
}

.form-select.down-arrow {
  background-image: url(../assets/svg/arrow-down.svg);
}

.form-select[multiple],
.form-select[size]:not([size="1"]) {
  padding-right: 0.625rem;
  height: auto;
  min-height: 32px;
}

textarea.form-control {
  height: auto;
  resize: none;
}

.form-control:focus,
.form-select:focus {
  color: #000;
  background-color: #fff;
  border-color: var(--bs-primary);
  outline: 0;
  box-shadow: none;
}

.form-control::-moz-placeholder {
  color: #B7B7B7;
  opacity: 1;
}

.form-control::placeholder {
  color: #B7B7B7;
  opacity: 1;
}

.form-buttons .btn+.btn {
  margin-left: .5rem;
}

.form-btn-reverse .form-buttons {
  display: flex;
  flex-flow: row-reverse;
  justify-content: flex-end;
}

.form-btn-reverse .form-buttons .btn+.btn {
  margin-left: 0;
  margin-right: .5rem;
}

/*input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator { display: none; -webkit-appearance: none;}*/

.form-control-sm,
.form-select-sm {
  padding: 0.25rem 0.375rem;
  line-height: .875rem;
  height: 24px;
  min-height: 24px;
}

.form-select-sm {
  padding-right: 1.5rem;
  background-position: right 0.375rem center;
}

.form-select-tra {
  padding-left: 0;
  padding-right: 1rem;
  border: none;
  background-position: right center;
  background-size: 12px 12px;
}
.form-select-tra:disabled {
  background-color: transparent;
  opacity: .75;
  cursor: not-allowed;
}

.input-group-icon {
  position: relative;
  width: 100%;
  padding-right: 0;
}

.input-group-icon>.input-append-icon {
  display: flex;
  align-items: center;
  position: absolute;
  right: -1.25rem;
  top: .4375rem;
}

.input-group-icon.ivac>.input-append-icon {
  top: 50%;
  transform: translateY(-50%);
}

.tags-input .tagify,
.tags-input .tagify:hover {
  padding: .1875rem .25rem;
  color: #000;
  background: #fff;
  border-color: var(--bs-border-color);
  font-size: .8rem;
  line-height: 1.125rem;
  font-weight: 400;
  border-radius: 3px;
}

.tags-input .tagify.form-control {
  height: auto;
}

.tags-input .tagify .tagify__tag {
  margin: .125rem;
  overflow: hidden;
}

.tags-input .tagify .tagify__tag>div {
  background-color: var(--bs-primary);
  color: #fff;
  font-size: .65rem;
  line-height: .75rem;
  padding: .25rem .375rem .25rem 0;
  max-width: calc(100% - 18px);
}

.tags-input .tagify .tagify__tag>div .tagify__tag-text {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}

.tags-input .tagify .tagify__tag>div:before {
  background-color: var(--bs-primary);
  box-shadow: none;
}

.tags-input .tagify .tagify__input {
  padding: .25rem;
  margin: .125rem;
  font-size: .75rem;
  line-height: 1;
}

.tags-input .tagify.tagify--focus {
  border-color: var(--bs-primary);
}

.tags-input .tagify .tagify__tag__removeBtn {
  margin: .125rem;
  order: 0;
  color: #fff;
  background-color: var(--bs-primary);
}

.tags-input .tagify .tagify__tag:focus div:before,
.tags-input .tagify .tagify__tag:hover:not([readonly]) div:before {
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  box-shadow: none;
}

.tags-input .tagify .tagify__tag__removeBtn:hover+div>span {
  opacity: 1;
}

.form-control.w-40 {
  width: 40px;
}

.form-control.w-60 {
  width: 60px;
}

.form-control.w-90 {
  width: 90px;
}

.form-control.w-140 {
  width: 140px;
}

.form-control.w-150 {
  width: 150px;
}

.select2.select2-container {
  width: 100% !important;
}

.select2-container .select2-selection--single {
  min-height: 32px;
}

.select2-container--default .select2-selection--single,
.select2-dropdown,
.select2-container--default .select2-search--dropdown .select2-search__field {
  border-color: var(--bs-border-color);
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 30px;
}

.datepicker {
  padding-left: 1.25rem;
  width: 154px;
  background-image: url(../assets/svg/calendar.svg);
  background-repeat: no-repeat;
  background-position: 6px center;
  background-size: 11px 11px;
}

.timepicker {
  padding-left: 1.25rem;
  /* width: 150px;*/
  background-image: url(../assets/svg/time.svg);
  background-repeat: no-repeat;
  background-position: 6px center;
  background-size: 11px 11px;
}

.css-b62m3t-container .css-x19aeh-control,
.css-b62m3t-container .css-x19aeh-control:hover,
.css-b62m3t-container .css-cw0kh6-control {
  min-height: 32px;
  border-color: var(--bs-border-color);
  box-shadow: none;
  border-radius: 3px;
}

.css-b62m3t-container .css-cw0kh6-control,
.css-b62m3t-container .css-cw0kh6-control:hover {
  color: #000;
  background-color: #fff;
  border-color: var(--bs-primary);
  outline: 0;
  box-shadow: none;
}

.css-b62m3t-container.is-invalid .css-x19aeh-control,
.css-b62m3t-container.is-invalid .css-x19aeh-control:hover,
.css-b62m3t-container.is-invalid .css-cw0kh6-control {
  border-color: var(--bs-danger);
}

.css-b62m3t-container .css-tlfecz-indicatorContainer,
.css-b62m3t-container .css-1gtu0rj-indicatorContainer {
  padding: .375rem .5rem;
  cursor: pointer;
}

.css-b62m3t-container .css-tlfecz-indicatorContainer .svg-arrow-icon,
.css-b62m3t-container .css-1gtu0rj-indicatorContainer .svg-arrow-icon {
  font-size: 1rem;
}

/*.css-b62m3t-container .css-tlfecz-indicatorContainer .css-tj5bde-Svg,
.css-b62m3t-container .css-1gtu0rj-indicatorContainer .css-tj5bde-Svg{ background: url(../assets/svg/plus-icon.svg) center center no-repeat; background-size: 1rem 1rem; width: 30px; height: 30px;}*/
/*.css-b62m3t-container .css-tlfecz-indicatorContainer .css-tj5bde-Svg path,
.css-b62m3t-container .css-1gtu0rj-indicatorContainer .css-tj5bde-Svg path{ display: none;}*/

.css-b62m3t-container .css-g1d714-ValueContainer {
  padding: .125rem .25rem;
}

.css-b62m3t-container .css-g1d714-ValueContainer .css-13jmfyo-multiValue {
  background-color: var(--bs-primary);
  color: #fff;
  flex-direction: row-reverse;
  border-radius: 3px;
}

.css-b62m3t-container .css-g1d714-ValueContainer .css-13jmfyo-multiValue .css-10ku62j {
  color: #fff;
  font-size: .65rem;
  line-height: .75rem;
  padding: .25rem .375rem .25rem 0;
}

.css-b62m3t-container .css-g1d714-ValueContainer .css-13jmfyo-multiValue .css-5dgs50 {
  padding: 0;
  margin: .125rem;
}

.css-b62m3t-container .css-g1d714-ValueContainer .css-13jmfyo-multiValue .css-5dgs50 .css-tj5bde-Svg {
  width: 12px;
  height: 12px;
}

.sd-height-sm .css-i0syzg-menu .css-4ljt47-MenuList {
  max-height: 180px;
}

.invalid-feedback {
  color: var(--bs-danger);
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border-color: var(--bs-danger);
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: var(--bs-danger);
  box-shadow: none;
}

.was-validated .form-select:invalid,
.form-select.is-invalid {
  border-color: var(--bs-danger);
}

.was-validated .form-select:invalid:focus,
.form-select.is-invalid:focus {
  border-color: var(--bs-danger);
  box-shadow: none;
}

.was-validated .form-check-input:invalid,
.form-check-input.is-invalid,
.form-check-input.is-invalid:focus {
  border-color: var(--bs-danger);
}

.was-validated .form-check-input:invalid:checked,
.form-check-input.is-invalid:checked {
  background-color: var(--bs-danger);
  border-color: var(--bs-danger);
}

.was-validated .form-check-input:invalid:focus,
.form-check-input.is-invalid:focus {
  box-shadow: none;
}

.was-validated .form-check-input:invalid~.form-check-label,
.form-check-input.is-invalid~.form-check-label {
  color: var(--bs-danger);
}

.btn {
  display: inline-block;
  font-weight: 500;
  line-height: 1;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .5625rem .75rem;
  font-size: .75rem;
  border-radius: 3px;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn:hover {
  color: #212529;
}

.btn-check:focus+.btn,
.btn:focus {
  outline: 0;
  box-shadow: none;
}

.btn:disabled,
.btn.disabled,
fieldset:disabled .btn {
  pointer-events: none;
  opacity: 1;
}

.btn-link {
  font-weight: 400;
  color: var(--bs-primary);
  text-decoration: none;
}

.btn-link-lg {
  font-size: 1rem;
  font-weight: 500;
}

.btn-link:hover {
  color: var(--bs-primary-hover);
}

.btn-link:disabled,
.btn-link.disabled {
  color: var(--bs-primary-disabled);
}

.btn-lg,
.btn-group-lg>.btn {
  padding: 1rem;
  font-size: 1.25rem;
  border-radius: 3px;
}

.btn-md,
.btn-group-md>.btn {
  padding: .875rem;
  font-size: 1rem;
  border-radius: 3px;
}

.btn-sm,
.btn-group-sm>.btn {
  padding: 0.375rem .5rem;
  font-size: 0.8rem;
  line-height: .75rem;
  border-radius: 3px;
}

.btn-link-icon {
  padding: 0;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
}

.link-s {
  font-size: .65rem;
  font-weight: 500;
}

.btn-add-icon {
  display: inline-block;
  padding: 0;
  width: 30px;
  height: 30px;
  border: none;
  background-color: transparent;
  background-image: url(../assets/svg/add-new.svg);
  background-repeat: no-repeat;
  background-size: 28px 28px;
  background-position: center center;
  vertical-align: top;
}

.btn-add-bordered {
  display: inline-block;
  padding: .4375rem;
  width: 32px;
  height: 32px;
  border: 1px solid var(--bs-border-color);
  background-color: #fff;
}

.btn-add-bordered .icon-add {
  background-size: cover;
}

.btn-add-bordered:hover {
  border-color: var(--bs-primary);
}

.btn-gray-bordered {
  display: inline-block;
  font-size: .8rem;
  line-height: 1.125rem;
  padding: .375rem .625rem;
  border: 1px solid var(--bs-border-color);
  background-color: #fff;
  border-radius: 3px;
}
.btn-gray-bordered:hover {
  background-color: #fafafa;
}

/*.btn-gray-bordered .icon {
  vertical-align: top;
}*/

.mw-50 {
  min-width: 50px;
  max-width: 100%;
}

.mw-100 {
  min-width: 100px;
  max-width: 100%;
}

.mw-150 {
  min-width: 150px;
  max-width: 100%;
}

.w-120 {
  width: 120px;
  max-width: 100%;
}

.mxw-120 {
  max-width: 120px;
}

.mxw-150 {
  max-width: 150px;
}

.btn-primary {
  color: #fff;
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.btn-primary:hover,
.btn-check:focus+.btn-primary,
.btn-primary:focus,
.btn-check:checked+.btn-primary,
.btn-check:active+.btn-primary,
.btn-primary:active,
.btn-primary.active,
.show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: var(--bs-primary-hover);
  border-color: var(--bs-primary-hover);
  box-shadow: none;
}

.btn-check:checked+.btn-primary:focus,
.btn-check:active+.btn-primary:focus,
.btn-primary:active:focus,
.btn-primary.active:focus,
.show>.btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-primary:disabled,
.btn-primary.disabled {
  color: #fff;
  background-color: var(--bs-primary-disabled);
  border-color: var(--bs-primary-disabled);
}

.btn-outline-primary {
  color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.btn-outline-primary:hover,
.btn-check:focus+.btn-outline-primary,
.btn-outline-primary:focus,
.btn-check:checked+.btn-outline-primary,
.btn-check:active+.btn-outline-primary,
.btn-outline-primary:active,
.btn-outline-primary.active,
.show>.btn-outline-primary.dropdown-toggle {
  color: var(--bs-primary-hover);
  background-color: transparent;
  border-color: var(--bs-primary-hover);
  box-shadow: none;
}

.btn-check:checked+.btn-outline-primary:focus,
.btn-check:active+.btn-outline-primary:focus,
.btn-outline-primary:active:focus,
.btn-outline-primary.active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-primary:disabled,
.btn-outline-primary.disabled {
  color: var(--bs-primary-disabled);
  background-color: transparent;
}

.btn-secondary {
  color: #fff;
  background-color: var(--bs-secondary);
  border-color: var(--bs-secondary);
}

.btn-secondary:hover,
.btn-check:focus+.btn-secondary,
.btn-secondary:focus,
.btn-check:checked+.btn-secondary,
.btn-check:active+.btn-secondary,
.btn-secondary:active,
.btn-secondary.active,
.show>.btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: var(--bs-secondary-hover);
  border-color: var(--bs-secondary-hover);
  box-shadow: none;
}

.btn-check:checked+.btn-secondary:focus,
.btn-check:active+.btn-secondary:focus,
.btn-secondary:active:focus,
.btn-secondary.active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-secondary:disabled,
.btn-secondary.disabled {
  color: #fff;
  background-color: var(--bs-secondary-disabled);
  border-color: var(--bs-secondary-disabled);
}

.btn-outline-secondary {
  color: var(--bs-secondary);
  border-color: var(--bs-secondary);
}

.btn-outline-secondary:hover,
.btn-check:focus+.btn-outline-secondary,
.btn-outline-secondary:focus,
.btn-check:checked+.btn-outline-secondary,
.btn-check:active+.btn-outline-secondary,
.btn-outline-secondary:active,
.btn-outline-secondary.active,
.show>.btn-outline-secondary.dropdown-toggle {
  color: var(--bs-secondary-hover);
  background-color: transparent;
  border-color: var(--bs-secondary-hover);
  box-shadow: none;
}

.btn-check:checked+.btn-outline-secondary:focus,
.btn-check:active+.btn-outline-secondary:focus,
.btn-outline-secondary:active:focus,
.btn-outline-secondary.active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-secondary:disabled,
.btn-outline-secondary.disabled {
  color: var(--bs-secondary-disabled);
  background-color: transparent;
}

.btn-dark {
  color: #fff;
  background-color: var(--bs-dark);
  border-color: var(--bs-dark);
}

.btn-dark:hover,
.btn-check:focus+.btn-dark,
.btn-dark:focus,
.btn-check:checked+.btn-dark,
.btn-check:active+.btn-dark,
.btn-dark:active,
.btn-dark.active,
.show>.btn-dark.dropdown-toggle {
  color: #fff;
  background-color: var(--bs-dark-hover);
  border-color: var(--bs-dark-hover);
  box-shadow: none;
}

.btn-check:checked+.btn-dark:focus,
.btn-check:active+.btn-dark:focus,
.btn-dark:active:focus,
.btn-dark.active:focus,
.show>.btn-dark.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-dark:disabled,
.btn-dark.disabled {
  color: #fff;
  background-color: var(--bs-dark-disabled);
  border-color: var(--bs-dark-disabled);
}

.btn-outline-dark {
  color: var(--bs-dark);
  border-color: var(--bs-dark);
}

.btn-outline-dark:hover,
.btn-check:focus+.btn-outline-dark,
.btn-outline-dark:focus,
.btn-check:checked+.btn-outline-dark,
.btn-check:active+.btn-outline-dark,
.btn-outline-dark:active,
.btn-outline-dark.active,
.show>.btn-outline-dark.dropdown-toggle {
  color: var(--bs-dark-hover);
  background-color: transparent;
  border-color: var(--bs-dark-hover);
  box-shadow: none;
}

.btn-check:checked+.btn-outline-dark:focus,
.btn-check:active+.btn-outline-dark:focus,
.btn-outline-dark:active:focus,
.btn-outline-dark.active:focus,
.show>.btn-outline-dark.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-dark:disabled,
.btn-outline-dark.disabled {
  color: var(--bs-dark-disabled);
  background-color: transparent;
}

.btn-accent {
  color: #fff;
  background-color: var(--bs-accent);
  border-color: var(--bs-accent);
}

.btn-accent:hover,
.btn-check:focus+.btn-accent,
.btn-accent:focus,
.btn-check:checked+.btn-accent,
.btn-check:active+.btn-accent,
.btn-accent:active,
.btn-accent.active,
.show>.btn-accent.dropdown-toggle {
  color: #fff;
  background-color: var(--bs-accent-hover);
  border-color: var(--bs-accent-hover);
  box-shadow: none;
}

.btn-check:checked+.btn-accent:focus,
.btn-check:active+.btn-accent:focus,
.btn-accent:active:focus,
.btn-accent.active:focus,
.show>.btn-accent.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-accent:disabled,
.btn-accent.disabled {
  color: #fff;
  background-color: var(--bs-accent-disabled);
  border-color: var(--bs-accent-disabled);
}

.btn-outline-accent {
  color: var(--bs-accent);
  border-color: var(--bs-accent);
}

.btn-outline-accent:hover,
.btn-check:focus+.btn-outline-accent,
.btn-outline-accent:focus,
.btn-check:checked+.btn-outline-accent,
.btn-check:active+.btn-outline-accent,
.btn-outline-accent:active,
.btn-outline-accent.active,
.show>.btn-outline-accent.dropdown-toggle {
  color: var(--bs-accent-hover);
  background-color: transparent;
  border-color: var(--bs-accent-hover);
  box-shadow: none;
}

.btn-check:checked+.btn-outline-accent:focus,
.btn-check:active+.btn-outline-accent:focus,
.btn-outline-accent:active:focus,
.btn-outline-accent.active:focus,
.show>.btn-outline-accent.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-accent:disabled,
.btn-outline-accent.disabled {
  color: var(--bs-accent-disabled);
  background-color: transparent;
}

.btn-success {
  color: #fff;
  background-color: var(--bs-success);
  border-color: var(--bs-success);
}

.btn-success:hover,
.btn-check:focus+.btn-success,
.btn-success:focus,
.btn-check:checked+.btn-success,
.btn-check:active+.btn-success,
.btn-success:active,
.btn-success.active,
.show>.btn-success.dropdown-toggle {
  color: #fff;
  background-color: var(--bs-success-hover);
  border-color: var(--bs-success-hover);
  box-shadow: none;
}

.btn-check:checked+.btn-success:focus,
.btn-check:active+.btn-success:focus,
.btn-success:active:focus,
.btn-success.active:focus,
.show>.btn-success.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-success:disabled,
.btn-success.disabled {
  color: #fff;
  background-color: var(--bs-success-disabled);
  border-color: var(--bs-success-disabled);
}

.btn-outline-success {
  color: var(--bs-success);
  border-color: var(--bs-success);
}

.btn-outline-success:hover,
.btn-check:focus+.btn-outline-success,
.btn-outline-success:focus,
.btn-check:checked+.btn-outline-success,
.btn-check:active+.btn-outline-success,
.btn-outline-success:active,
.btn-outline-success.active,
.show>.btn-outline-success.dropdown-toggle {
  color: var(--bs-success-hover);
  background-color: transparent;
  border-color: var(--bs-success-hover);
  box-shadow: none;
}

.btn-check:checked+.btn-outline-success:focus,
.btn-check:active+.btn-outline-success:focus,
.btn-outline-success:active:focus,
.btn-outline-success.active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-success:disabled,
.btn-outline-success.disabled {
  color: var(--bs-success-disabled);
  background-color: transparent;
}

.btn-danger {
  color: #fff;
  background-color: var(--bs-danger);
  border-color: var(--bs-danger);
}

.btn-danger:hover,
.btn-check:focus+.btn-danger,
.btn-danger:focus,
.btn-check:checked+.btn-danger,
.btn-check:active+.btn-danger,
.btn-danger:active,
.btn-danger.active,
.show>.btn-danger.dropdown-toggle {
  color: #fff;
  background-color: var(--bs-danger-hover);
  border-color: var(--bs-danger-hover);
  box-shadow: none;
}

.btn-check:checked+.btn-danger:focus,
.btn-check:active+.btn-danger:focus,
.btn-danger:active:focus,
.btn-danger.active:focus,
.show>.btn-danger.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-danger:disabled,
.btn-danger.disabled {
  color: #fff;
  background-color: var(--bs-danger-disabled);
  border-color: var(--bs-danger-disabled);
}

.btn-outline-danger {
  color: var(--bs-danger);
  border-color: var(--bs-danger);
}

.btn-outline-danger:hover,
.btn-check:focus+.btn-outline-danger,
.btn-outline-danger:focus,
.btn-check:checked+.btn-outline-danger,
.btn-check:active+.btn-outline-danger,
.btn-outline-danger:active,
.btn-outline-danger.active,
.show>.btn-outline-danger.dropdown-toggle {
  color: var(--bs-danger-hover);
  background-color: transparent;
  border-color: var(--bs-danger-hover);
  box-shadow: none;
}

.btn-check:checked+.btn-outline-danger:focus,
.btn-check:active+.btn-outline-danger:focus,
.btn-outline-danger:active:focus,
.btn-outline-danger.active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-danger:disabled,
.btn-outline-danger.disabled {
  color: var(--bs-danger-disabled);
  background-color: transparent;
}

.btn-warning {
  color: #000;
  background-color: var(--bs-warning);
  border-color: var(--bs-warning);
}

.btn-warning:hover,
.btn-check:focus+.btn-warning,
.btn-warning:focus,
.btn-check:checked+.btn-warning,
.btn-check:active+.btn-warning,
.btn-warning:active,
.btn-warning.active,
.show>.btn-warning.dropdown-toggle {
  color: #000;
  background-color: var(--bs-warning-hover);
  border-color: var(--bs-warning-hover);
  box-shadow: none;
}

.btn-check:checked+.btn-warning:focus,
.btn-check:active+.btn-warning:focus,
.btn-warning:active:focus,
.btn-warning.active:focus,
.show>.btn-warning.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-warning:disabled,
.btn-warning.disabled {
  color: #ACACAC;
  background-color: var(--bs-warning-disabled);
  border-color: var(--bs-warning-disabled);
}

.btn-outline-warning {
  color: #000;
  border-color: var(--bs-warning);
}

.btn-outline-warning:hover,
.btn-check:focus+.btn-outline-warning,
.btn-outline-warning:focus,
.btn-check:checked+.btn-outline-warning,
.btn-check:active+.btn-outline-warning,
.btn-outline-warning:active,
.btn-outline-warning.active,
.show>.btn-outline-warning.dropdown-toggle {
  color: #000;
  background-color: transparent;
  border-color: var(--bs-warning-hover);
  box-shadow: none;
}

.btn-check:checked+.btn-outline-warning:focus,
.btn-check:active+.btn-outline-warning:focus,
.btn-outline-warning:active:focus,
.btn-outline-warning.active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
  box-shadow: none;
}

.btn-outline-warning:disabled,
.btn-outline-warning.disabled {
  color: #9D9D9D;
  border-color: var(--bs-warning-disabled);
  background-color: transparent;
}

.form-check {
  display: block;
  min-height: 1rem;
  padding-left: 1.5rem;
  margin-bottom: 0.125rem;
  line-height: 1.125rem;
}

.form-check-inline {
  display: inline-block;
}

.form-check.no-label {
  padding-left: 1rem;
}

.form-check.no-label .form-check-input {
  margin-left: -1rem;
}

.form-check-input {
  border-color: #5F5F5F;
}

.form-check-input[type=checkbox],
.form-radio-check .form-check-input[type=radio] {
  font-size: 1rem;
  margin-top: .0625rem;
  border-radius: 3px;
}

.form-check-input[type=radio] {
  font-size: 1rem;
  margin-top: .125rem;
  border-radius: 50%;
}

.form-check-input:active {
  filter: brightness(100%);
}

.form-check-input:focus {
  border-color: var(--bs-primary);
  outline: 0;
  box-shadow: none;
}

.form-check-input:checked {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
}

.form-check-input:checked[type=checkbox],
.form-radio-check .form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input[type=checkbox]:indeterminate {
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}

.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}

.form-check-input[disabled]~.form-check-label,
.form-check-input:disabled~.form-check-label {
  opacity: 0.5;
}

.page-content {
  background: #fff;
  border: 1px solid #F2F2F2;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.16);
  border-radius: 3px;
}

.page-content.pc-rb {
  margin-right: 1.25rem;
  margin-bottom: 1.25rem;
}

.me-mb {
  margin-right: 1.25rem;
  margin-bottom: 1.25rem;
}

.page-content .page-title-view {
  padding: .5rem 1rem;
  border-bottom: 1px solid var(--bs-border-color);
  align-items: center;
}

.page-content .page-title-view .body-l {
  line-height: 20px;
  word-break: break-all;
}

.page-content>.table-list {
  padding: .75rem 1.25rem 1.25rem;
}

.page-content .page-body {
  padding: 1.25rem;
}

.page-content .table-list {
  margin-top: -4px;
}

.table> :not(:first-child) {
  border-top: none;
}

.table th {
  border-bottom-color: #DEDEDE;
  padding: .5rem .5rem;
  font-size: .8rem;
  font-weight: 700;
}

.table td {
  border-bottom-color: #DEDEDE;
  padding: .5rem .5rem;
  font-size: .8rem;
  font-family: 'Roboto', sans-serif;
  line-height: 1rem;
}

.table-ftd-border-none th:first-child,
.table-ftd-border-none td:first-child {
  border-bottom-color: transparent;
}

.table-ftd-border-none th:nth-child(2),
.table-ftd-border-none td:nth-child(2) {
  padding-left: 0;
}

.table-sm th {
  padding-left: .25rem;
  padding-right: .25rem;
}

.table-sm td {
  padding: .25rem;
}

.table th:first-child,
.table td:first-child {
  padding-left: 0;
}

.table th:last-child,
.table td:last-child {
  padding-right: 0;
}

.table.table-lrb-0 tbody tr:last-child td {
  border-bottom: 0;
}

.table td .badge {
  padding: .25rem .375rem;
  font-size: .75rem;
  line-height: 1;
  min-width: 75px;
  font-weight: 500;
}

.table th#actions {
  text-align: right;
}

.table th .btn-action {
  padding: 0;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border: none;
  background-color: transparent;
}

.table th .dropdown-menu[aria-labelledby="dropdownMenuButton-thAction"] {
  transform: translate(0, 21px) !important;
}

.table td .MuiButtonBase-root {
  padding: .25rem;
}

.table td .MuiButtonBase-root .fas {
  color: #000;
}

.table td .icon-14 {
  margin-top: 1px;
  vertical-align: top;
}

.table th .dropdown,
.table td .dropdown {
  line-height: 1;
}

.table>tbody>.drag-list-wrap {
  display: table;
}

.table>tbody>.drag-list-wrap>div {
  display: table-row-group;
}

.table>tbody>.drag-list-wrap>div>div {
  display: table-row;
}

.table .col-space,
.table .col-space-sm {
  padding: 0;
}

.table .break-word {
  word-break: break-word;
}

.table-footer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 1rem 1rem;
  margin-left: -1.5rem;
}

.table-footer>* {
  margin-top: 1rem;
  margin-left: 1.5rem;
}

/*.table-footer > * + div{ margin-left: 1.5rem;}*/
.table-footer .pagination {
  margin-bottom: 0;
}

.table-thead-medium thead th {
  font-weight: 500;
}

.pagination .page-item {
  margin-left: .1875rem;
}

.pagination .page-item .page-link {
  width: 25px;
  height: 25px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  color: #000;
  border-radius: 3px;
}

.pagination .page-prev .page-link,
.pagination .page-next .page-link {
  font-size: 11px;
  color: var(--bs-primary);
}

.pagination .page-item.active .page-link,
.pagination .page-item .page-link:hover {
  background-color: var(--bs-primary);
  color: #fff;
}

.pagination .page-item.disabled .page-link {
  opacity: .3;
}

.table-footer .page-result {
  line-height: 22px;
}

.table-footer .page-result .dropdown-more {
  display: inline-block;
}

.table-footer .page-result .dropdown-more .btn {
  vertical-align: top;
}

.table-footer .page-result .dropdown-more .dropdown-menu {
  min-width: 5rem;
}

.table-wrap .table-row {
  border-bottom: 1px solid #DEDEDE;
}

.table-wrap .table-thead .col {
  font-size: .8rem;
  font-weight: 700;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.table-wrap .table-tbody .col,
.table-wrap .table-tbody .col-12 {
  font-size: .8rem;
  font-family: 'Roboto', sans-serif;
  line-height: 1rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.table-wrap .table-row>.row>.col:first-child {
  flex: 0 0 auto;
  width: 10%;
}

.table-wrap .table-row>.row>.col:last-child {
  flex: 0 0 auto;
  width: 6%;
}

.table-drag-row .table-tbody>.drag-list-wrap>div>div {
  margin-bottom: 0 !important;
}

.table-drag-row .table-tbody .table-row {
  position: relative;
}

.table-drag-row .table-tbody .table-row .icon-up-down {
  position: absolute;
  left: -1.625rem;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.375rem;
  cursor: move;
}

.table-offer-content {
  min-width: 980px;
}

.table-deliveries {
  min-width: 940px;
}

.disabled {
  pointer-events: none !important;
  cursor: not-allowed !important;
}

.pagination-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.pagination-wrap>*+div {
  margin-left: 1.5rem;
}

.pagination-wrap .pagination {
  margin-bottom: 0;
}

.page-goto {
  position: relative;
  width: 58px;
  display: inline-block;
}

.page-goto label {
  position: absolute;
  left: 4px;
  top: -6px;
  padding: 0 2px;
  background: #fff;
  font-size: 8px;
}

.page-goto input {
  width: 100%;
  height: 25px;
  padding: 0 1.125rem 0 .375rem;
  border: 1px solid #DEDEDE;
  border-radius: 3px;
  box-shadow: none;
  outline: none;
}

.page-goto button {
  background: transparent;
  border: none;
  padding: 0;
  position: absolute;
  top: 1px;
  right: 1px;
  bottom: 1px;
  width: 1.125rem;
  font-size: 11px;
  text-align: center;
}

.page-goto input::-webkit-outer-spin-button,
.page-goto input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.page-goto input[type=number] {
  -moz-appearance: textfield;
}

/* Heading */
.heading-sm h1 {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

/* Card */
.card {
  border: 1px solid #F2F2F2;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, .16);
  border-radius: 3px;
}

.card-header {
  border-bottom: 1px solid var(--bs-border-color);
}

.card-view .card-header {
  background-color: #fff;
  padding-right: .5rem;
}

.card-view-list {
  border-width: 1px 0;
  border-color: var(--bs-border-color);
  box-shadow: none;
  border-radius: 0;
}

.card-view-list .btn-collapse-title {
  font-size: .8rem;
  padding: .5rem 0;
}

.card-view-list+.card-view-list {
  margin-top: -1px;
}

.card-view-body {
  border-width: 1px 0 0;
  border-color: var(--bs-border-color);
  box-shadow: none;
  border-radius: 0;
}

.card-view-body .btn-collapse-title {
  font-size: .8rem;
  padding: .625rem 1rem;
}

/* Login */
.login-box {
  padding: 1rem;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-box>.card {
  width: 400px;
  max-width: 100%;
  padding: 1.5rem 2rem 2rem;
}

.login-box .form-control {
  padding: 0;
  border-width: 0;
  border-bottom-width: 1px;
  border-radius: 0;
}

.login-box .form-icon {
  padding-left: 2rem;
  background-size: 18px 18px;
  background-position: left center;
  background-repeat: no-repeat;
}

.login-box .icon-email {
  background-image: url(../assets/svg/mail.svg);
}

.login-box .icon-lock {
  background-image: url(../assets/svg/lock.svg);
}

.login-box .form-check-input {
  border-color: #5F5F5F;
  /*margin-top: .25rem;*/
}

.login-box .form-check-input:focus,
.login-box .form-check-input:checked {
  border-color: var(--bs-primary);
}

.view-label-160 .item-label {
  width: 160px;
}

.view-items .item-label {
  padding-top: 3px;
  padding-bottom: 3px;
  font-weight: 500;
}

.view-items .item-value {
  padding-top: 3px;
  padding-bottom: 3px;
  word-wrap: break-word;
}

.form-view-mode {
  border: 1px solid #D6D6D6;
  border-radius: 3px;
}

.form-view-mode>.row {
  align-items: center;
}

.form-view-mode>.row+.row {
  border-top: 1px solid #D6D6D6;
}

.form-view-mode .col-form-label {
  padding: .25rem .25rem .25rem 1rem;
}

.form-view-mode .item-value {
  padding: .25rem;
  word-wrap: break-word;
}

.hover-icon-show .btn-shover {
  display: none;
  padding: 0;
}

.hover-icon-show:hover .btn-shover {
  display: inline-block;
}

.hover-icon-show .btn-shover .icon-size {
  vertical-align: top;
  opacity: .25;
}

.form-view-mode .drag-list-wrap>*>* {
  margin-bottom: 0 !important;
}

.form-view-mode .drag-list-wrap>*>*:not(:last-child) {
  border-bottom: 1px solid #D6D6D6;
}

.form-edit-mode .form-mb-row {
  margin-bottom: .625rem;
}

.form-view-mode .form-mb-row {
  border-bottom: 1px solid #D6D6D6;
}

.items-bordered {
  border: 1px solid #D6D6D6;
  border-radius: 3px;
}

.items-bordered>.row+.row {
  border-top: 1px solid #D6D6D6;
}

.items-bordered .item-label {
  padding: .25rem .25rem .25rem 1rem;
  font-weight: 500;
}

.items-bordered .item-value {
  padding: .25rem;
  word-wrap: break-word;
}

.row-table {
  padding-left: .25rem;
}

.row-table .row-thead {
  font-weight: 600;
  padding: .1875rem .125rem;
}

.row-table .row-tbody {
  border-top: 1px solid #D6D6D6;
  padding: .1875rem .125rem;
}

/*.row-tbody:last-child{ padding-bottom: 0;}*/

.page-tabs {
  border-bottom-color: var(--bs-border-color);
}

.page-tabs .nav-link {
  line-height: 18px;
  font-weight: 300;
  color: #000;
  border-radius: 3px;
}

.page-tabs .nav-item.show .nav-link,
.page-tabs .nav-link.active {
  font-weight: 500;
  color: #000;
  border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-white);
}

.tabs-bg-white {
  background: #fff;
  border: 1px solid #f2f2f2;
  border-radius: 3px;
}

.file-previewer-wrap>div {
  border: none !important;
  padding: 0 !important;
  width: 100% !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  text-align: center;
}

.file-previewer-wrap>div img {
  max-width: 100%;
  width: auto !important;
  height: auto !important;
}

.file-previewer-wrap>div object {
  height: calc(100vh - 242px) !important;
}

.dropdown-menu.w-auto {
  min-width: auto
}

.dropdown-hover:hover .dropdown-menu {
  display: block;
}

.items-attachment {
  width: 24px;
  height: 24px;
  border-radius: 3px;
}

.items-attachment img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.icons-mail .icon {
  width: 20px;
  height: 18px;
  display: inline-block;
  vertical-align: top;
}

.mail-row {
  font-size: .65rem;
}

.mail-row .item-label {
  font-weight: 700;
}

.mail-row .item-value {
  font-weight: 500;
}

.mail-row .item-date {
  font-weight: 500;
  color: #606060;
}

.row-bt+.row-bt {
  padding-top: 1.25rem;
  margin-top: .25rem;
  border-top: 1px solid var(--bs-border-color);
}

.icon-collapsed .fa-chevron-right {
  transform: rotate(90deg);
  transition: all .3s ease;
}

.icon-collapsed.collapsed .fa-chevron-right {
  transform: rotate(0deg);
}

.MuiModal-root .MuiPaper-root {
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .16);
}

.MuiPaper-root .MuiList-root {
  padding: .5rem 0;
}

.MuiPaper-root .MuiList-root .MuiMenuItem-root {
  padding: .375rem .875rem;
  font-size: .8rem;
  line-height: 1rem;
  min-height: auto;
}

.MuiDialog-root .MuiPaper-root .MuiDialogTitle-root {
  font-size: 1rem;
  font-weight: 500;
  padding: .5rem 1rem;
  border-bottom: 1px solid var(--bs-border-color);
  font-family: var(--bs-body-font-family);
}

.MuiDialog-second-title {
  border-top: 1px solid var(--bs-border-color);
  padding: 1rem 1rem 0;
  margin: 0 -1rem;
}

.MuiDialog-root .MuiPaper-root .MuiDialogTitle-root .btn-close {
  position: absolute;
  right: .625rem;
  top: .625rem;
  background-size: 10px;
  opacity: 1;
  box-shadow: none;
}

.MuiDialog-root .MuiPaper-root .MuiDialogContent-root {
  padding: 1rem 1rem 0;
  border: none;
}

.MuiDialog-root .MuiPaper-root .MuiDialogContent-root .MuiDialogContentText-root {
  font-size: .8rem;
  font-family: var(--bs-body-font-family);
}

.MuiDialog-root .MuiPaper-root .MuiDialogActions-root {
  padding: 0 1rem 1rem;
}

.MuiDialog-root .MuiPaper-root .MuiDialogActions-root.bordered {
  padding: 1rem;
  border-top: 1px solid var(--bs-border-color);
}

.MuiDialog-root .MuiPaper-root .MuiDialogActions-root .row {
  margin: 0;
}

.MuiDialog-root .MuiPaper-root .MuiDialogActions-root .col-12 {
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
}

.MuiDialog-root .MuiPaper-root .MuiDialogActions-root .col-12 .btn {
  margin-left: .75px;
}

.MuiDialog-root .MuiPaper-root .MuiDialogBody {
  width: 388px;
  max-width: 100%;
}

.MuiDialog-root .MuiPaper-root .MuiDialogAlert {
  padding: 1rem 0 1.5rem;
}

.MuiDialog-button-outer .MuiPaper-root {
  background-color: transparent;
  border-radius: 0;
  box-shadow: none;
}

.MuiDialog-button-outer .MuiPaper-root .MuiDialog-button-body {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .16);
  overflow: auto;
}

.select-dialog__menu-portal {
  z-index: 1301 !important;
}

.progressbar .MuiLinearProgress-root {
  height: 8px;
  background-color: #F2F2F2;
  border-radius: .5rem;
}

.progressbar .MuiLinearProgress-root .MuiLinearProgress-bar {
  background-color: #8e8e8e;
}

.progressbar .MuiLinearProgress-root[aria-valuenow="100"] .MuiLinearProgress-bar {
  background-color: var(--bs-success);
}

.MuiCard .MuiAccordion-root {
  border: 1px solid #F2F2F2;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, .16);
  border-radius: 3px;
}

.MuiCard .MuiAccordion-root .MuiAccordionSummary-root {
  background-color: #fff;
  padding: 0;
  min-height: 34px;
  position: relative;
}

.MuiCard .MuiAccordion-root .MuiAccordionSummary-root .MuiAccordionSummary-content {
  margin: 0;
  justify-content: space-between;
  z-index: 1;
}

.MuiCard .MuiAccordion-root .MuiAccordionSummary-root h2 {
  padding: .75rem 1rem .75rem 2.25rem;
  display: block;
  line-height: 1;
  width: 100%;
}

.MuiCard .MuiAccordion-root .MuiAccordionSummary-root .MuiAccordionSummary-expandIconWrapper {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%) rotate(0deg);
}

.MuiCard .MuiAccordion-root .MuiAccordionSummary-root .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
}

.MuiCard .MuiAccordion-root .MuiAccordionSummary-root .MuiAccordionSummary-expandIconWrapper svg {
  font-size: .75rem;
  color: #000;
}

.MuiCard .MuiAccordion-root .MuiAccordionSummary-root .actions-right {
  display: flex;
  align-items: center;
  padding-right: .25rem;
}

.MuiCard .MuiAccordion-root .MuiAccordionSummary-root .actions-right .MuiButtonBase-root {
  width: 24px;
  height: 24px;
  padding: .125rem;
  color: #000;
}

.MuiCard .MuiAccordion-root .MuiAccordionDetails-root {
  border-top: 1px solid var(--bs-border-color);
}

.MuiTooltip-popper .MuiTooltip-tooltip {
  background-color: var(--bs-primary);
  font-size: .8rem;
  padding: 5px 12px 7px;
  line-height: 18px;
}

.MuiTooltip-popper[data-popper-placement*="bottom"] .MuiTooltip-tooltip {
  margin-top: .375rem !important;
  margin-bottom: .375rem !important;
}

.btn-notes-mail {
  display: flex;
  align-items: center;
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.btn-notes-mail .MuiSvgIcon-fontSizeSmall {
  font-size: 1rem;
  margin: -1px 0;
}

.accordion .accordion-item {
  border: 1px solid #F2F2F2;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, .16);
  margin-bottom: 1.25rem;
  height: calc(100% - 1.25rem);
  border-radius: 3px;
}

.accordion [class*="col-"]>.accordion-item {
  height: calc(100% - 1.25rem);
}

.accordion .accordion-item .accordion-header {
  display: flex;
  justify-content: space-between;
}

.accordion .accordion-item .accordion-header .actions-right {
  padding: 0 .625rem 0 .5rem;
  display: flex;
  align-items: center;
}

.accordion .accordion-item .accordion-header .actions-right .dropdown {
  display: flex;
  align-items: center;
}

.accordion .accordion-item .accordion-header .actions-right>*+* {
  margin-left: .75rem;
}

.accordion .accordion-item .accordion-header .accordion-button,
.accordion .accordion-item .accordion-header .accordion-button:not(.collapsed) {
  padding: .75rem 1rem .75rem 2.25rem;
  font-size: 1rem;
  line-height: 1;
  font-weight: 500;
  font-family: var(--bs-body-font-family);
  background: #fff;
  color: #000;
  box-shadow: none;
}

.accordion .accordion-item .accordion-header .accordion-button:after {
  position: absolute;
  left: 1rem;
  top: 50%;
  width: .75rem;
  height: .75rem;
  background-size: .75rem .75rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23000000'%3e%3cpath fill-rule='evenodd' d='M6.23 20.23 8 22l10-10L8 2 6.23 3.77 14.46 12z'/%3e%3c/svg%3e");
  transform: translateY(-50%) rotate(90deg);
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.accordion .accordion-item .accordion-header .accordion-button.collapsed:after {
  transform: translateY(-50%) rotate(0deg);
}

.accordion .accordion-item .accordion-header .accordion-button.after-none:after {
  display: none;
}

.accordion .accordion-item .accordion-header-input {
  position: relative;
  min-height: 54px;
}

.accordion .accordion-item .accordion-header-input .accordion-input {
  position: absolute;
  left: 2.25rem;
  top: .6875rem;
  z-index: 4;
}

.accordion .accordion-body {
  padding: 1rem;
  border-top: 1px solid var(--bs-border-color);
}

.accordion .accordion-body.py-12 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.accordion .search-bar {
  border-radius: 0;
}

.accordion .accordion-body .notes-accview {
  padding-left: 1rem;
  padding-right: 1rem;
}

.table-collapse .btn-collapse {
  background-color: transparent;
  border: none;
  width: 1rem;
  height: 1rem;
  background-size: .75rem .75rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23000000'%3e%3cpath fill-rule='evenodd' d='M6.23 20.23 8 22l10-10L8 2 6.23 3.77 14.46 12z'/%3e%3c/svg%3e");
  background-position: center center;
  background-repeat: no-repeat;
  transform: rotate(90deg);
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  display: inline-block;
  vertical-align: top;
}

.table-collapse .btn-collapse.collapsed {
  transform: rotate(0deg);
}

.table-collapse-inside tr td {
  border-top: 1px solid #DEDEDE;
  border-bottom: none;
}

.table-collapse-inside tr:first-child td {
  border-top: 1px;
}

.table-collapse-inside.table-collapse-ftdb-none tr.collapse+tr.collapse td:first-child,
.table-collapse-inside.table-collapse-ftdb-none tr.collapsing+tr.collapsing td:first-child {
  border-top: none;
}

.table-collapse-ftdb-none tr.collapse td:first-child {
  border-bottom: none;
}

.accordion-inside>.accordion-item {
  border-width: 1px 0 0;
  border-top-color: #DEDEDE;
  box-shadow: none;
  margin-bottom: 0;
  border-radius: 0;
}

.accordion-inside>.accordion-item>.accordion-header .accordion-button,
.accordion-inside>.accordion-item>.accordion-header .accordion-button:not(.collapsed) {
  padding: .6875rem 1rem .6875rem 2.25rem;
  font-size: .8rem;
  /*margin-right: -.875rem;*/
}

.accordion-inside>.accordion-item>.accordion-collapse>.accordion-body {
  padding: .25rem 1rem 1rem;
  border-top: none;
}

.accordion-inside>.accordion-item>.accordion-collapse>.accordion-body.bd-top {
  padding: .25rem 1rem;
  border-top: 1px solid var(--bs-border-color);
}

.accordion-inside>.accordion-item>.accordion-collapse>.accordion-body.lspace {
  padding-left: 2.25rem;
}

.accordion-inside>.accordion-item>.accordion-collapse>.accordion-body.tspace {
  padding-top: .75rem;
}

.accordion-button-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.accordion-button-wrap .accordion-button {
  width: auto;
}

.accordion-button-wrap .actions-form {
  padding: .75rem 0 .75rem 1rem;
}

.accordion-inside-drag>*>*>* {
  margin-bottom: 0 !important;
}

.accordion-inside-drag .accordion-item {
  border-width: 1px 0 0;
  border-top-color: #DEDEDE;
  box-shadow: none;
  margin-bottom: 0;
  border-radius: 0;
}

.accordion-inside-drag .accordion-item>.accordion-header .accordion-button,
.accordion-inside-drag .accordion-item>.accordion-header .accordion-button:not(.collapsed) {
  padding: .6875rem 1rem .6875rem 2.25rem;
  font-size: .8rem;
  margin-right: -.875rem;
}

.accordion-inside-drag .accordion-item>.accordion-collapse>.accordion-body {
  padding: .25rem 1rem 1rem;
  border-top: none;
}

.accordion-inside-drag .accordion-item>.accordion-collapse>.accordion-body.lspace {
  padding-left: 2.25rem;
}

.accordion-inside-drag .accordion-item>.accordion-collapse>.accordion-body.tspace {
  padding-top: .75rem;
}

.accordion-heading-secondary > .accordion-item > .accordion-header > .accordion-button,
.accordion-heading-secondary > .accordion-item > .accordion-header > .accordion-button:not(.collapsed),
.accordion-heading-secondary > .accordion-item > .accordion-header > *,
.accordion-heading-secondary.accordion-item > .accordion-header > .accordion-button,
.accordion-heading-secondary.accordion-item > .accordion-header > .accordion-button:not(.collapsed),
.accordion-heading-secondary.accordion-item > .accordion-header > * {
  background-color: var(--bs-secondary);
  color: #fff;
}

.accordion-heading-primary > .accordion-item > .accordion-header > .accordion-button,
.accordion-heading-primary > .accordion-item > .accordion-header > .accordion-button:not(.collapsed),
.accordion-heading-primary > .accordion-item > .accordion-header > *,
.accordion-heading-primary.accordion-item > .accordion-header > .accordion-button,
.accordion-heading-primary.accordion-item > .accordion-header > .accordion-button:not(.collapsed),
.accordion-heading-primary.accordion-item > .accordion-header > * {
  background-color: var(--bs-primary);
  color: #fff;
}

.accordion-heading-secondary > .accordion-item > .accordion-header > .accordion-button,
.accordion-heading-primary > .accordion-item > .accordion-header > .accordion-button,
.accordion-heading-secondary.accordion-item > .accordion-header > .accordion-button,
.accordion-heading-primary.accordion-item > .accordion-header > .accordion-button {
  border-radius: 0;
}

.accordion-heading-secondary > .accordion-item > .accordion-header > .accordion-button:after,
.accordion-heading-primary > .accordion-item > .accordion-header > .accordion-button:after,
.accordion-heading-secondary.accordion-item > .accordion-header > .accordion-button:after,
.accordion-heading-primary.accordion-item > .accordion-header > .accordion-button:after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23fff'%3e%3cpath stroke='%23fff' stroke-width='1' fill-rule='evenodd' d='M6.23 20.23 8 22l10-10L8 2 6.23 3.77 14.46 12z'/%3e%3c/svg%3e");
}

.accordion-heading-secondary > .accordion-item > .accordion-header > :first-child,
.accordion-heading-primary > .accordion-item > .accordion-header > :first-child,
.accordion-heading-secondary.accordion-item > .accordion-header > :first-child,
.accordion-heading-primary.accordion-item > .accordion-header > :first-child {
  border-top-left-radius: calc(.25rem - 1px);
}

.accordion-heading-secondary > .accordion-item > .accordion-header > .accordion-button.collapsed,
.accordion-heading-primary > .accordion-item > .accordion-header > .accordion-button.collapsed,
.accordion-heading-secondary.accordion-item > .accordion-header > .accordion-button.collapsed,
.accordion-heading-primary.accordion-item > .accordion-header > .accordion-button.collapsed {
  border-bottom-left-radius: calc(.25rem - 1px);
}

.accordion-heading-secondary > .accordion-item > .accordion-header > :last-child,
.accordion-heading-primary > .accordion-item > .accordion-header > :last-child,
.accordion-heading-secondary.accordion-item > .accordion-header > :last-child,
.accordion-heading-primary.accordion-item > .accordion-header > :last-child {
  border-top-right-radius: calc(.25rem - 1px);
}

.accordion-heading-secondary > .accordion-item > .accordion-header > .accordion-button.collapsed:last-child,
.accordion-heading-secondary > .accordion-item > .accordion-header > .accordion-button.collapsed + :last-child,
.accordion-heading-primary > .accordion-item > .accordion-header > .accordion-button.collapsed:last-child,
.accordion-heading-primary > .accordion-item > .accordion-header > .accordion-button.collapsed + :last-child,
.accordion-heading-secondary.accordion-item > .accordion-header > .accordion-button.collapsed:last-child,
.accordion-heading-secondary.accordion-item > .accordion-header > .accordion-button.collapsed + :last-child,
.accordion-heading-primary.accordion-item > .accordion-header > .accordion-button.collapsed:last-child,
.accordion-heading-primary.accordion-item > .accordion-header > .accordion-button.collapsed + :last-child {
  border-bottom-right-radius: calc(.25rem - 1px);
}

.accordion-heading-secondary > .accordion-item > .accordion-header > * > .dropdown-more .dropdown-toggle .fas,
.accordion-heading-primary > .accordion-item > .accordion-header > * > .dropdown-more .dropdown-toggle .fas,
.accordion-heading-secondary.accordion-item > .accordion-header > * > .dropdown-more .dropdown-toggle .fas,
.accordion-heading-primary.accordion-item > .accordion-header > * > .dropdown-more .dropdown-toggle .fas {
  color: #fff;
}

.accordion-heading-secondary > .accordion-item > .accordion-header .icon-add-dark,
.accordion-heading-primary > .accordion-item > .accordion-header .icon-add-dark,
.accordion-heading-secondary.accordion-item > .accordion-header .icon-add-dark,
.accordion-heading-primary.accordion-item > .accordion-header .icon-add-dark {
  background-image: url(../assets/svg/add-white.svg);
}

.files-inside .page-content {
  background: transparent;
  border: none;
  box-shadow: none;
  border-radius: 0;
}

/*.files-inside .page-content .table-list{ padding-top: 0;}*/
.files-inside .search-bar {
  margin-bottom: 0;
}

.file-upload-wrap {
  min-height: 144px;
  padding: 1rem;
  width: 100%;
  position: relative;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #f2f2f2;
  border: 1px dashed #6D7FEA;
  border-radius: 3px;
}

.file-upload-wrap input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.upload-text {
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.uploaded-files {
  border-top: 1px solid var(--bs-border-color);
}

.uploaded-files .row-file {
  position: relative;
  padding: .5rem .75rem .5rem 0;
  border-bottom: 1px solid var(--bs-border-color);
}

.uploaded-files .row-file .row {
  align-items: center;
}

.uploaded-files .row-file .item-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
}

.uploaded-files .row-file .item-close {
  font-size: 6px;
  padding: 1px;
  width: 8px;
  height: 8px;
  border: none;
  background-color: var(--bs-danger);
  background-size: 4px;
  background-position: center center;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
}

.page-pager {
  display: flex;
  padding-left: .5rem;
}

.btn-prev,
.btn-next {
  font-size: .625rem;
  width: 18px;
  height: 18px;
  line-height: 18px;
  padding: 0;
  background-color: transparent;
  border: 0;
  border-radius: 3px;
}

.btn-prev:hover,
.btn-next:hover {
  background-color: #f2f2f2;
}

.btn-prev[disabled],
.btn-next[disabled] {
  pointer-events: none;
  opacity: .5;
}

.card-collapse {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: none;
}

.btn-collapse-title {
  font-size: 1rem;
  padding: .625rem 1rem;
  width: 100%;
  text-align: left;
}

.btn-collapse-title .fas {
  font-size: .75rem;
  margin-right: .75rem;
  transform: rotate(90deg);
  vertical-align: top;
  margin-top: 1px;
}

.btn-collapse-title.collapsed .fas {
  transform: rotate(0deg);
}

.card-collapse .dropdown-more .dropdown-toggle {
  padding: .375rem;
}

.items-editor {
  background: #fff;
  border: 1px solid var(--bs-border-color);
  border-radius: 3px;
}

.items-editor .item-icons {
  padding: .125rem 0;
  background: #F6F8FA;
  border-radius: 3px 3px 0 0;
}

.items-editor .item-icons button {
  min-width: 24px;
  min-height: 24px;
  line-height: 1rem;
  padding: .25rem;
  margin: .125rem;
  border: none;
  background-color: transparent;
  border-radius: 3px;
}

.items-editor .item-icons button:hover {
  background-color: #e6e6e6;
}

.items-editor .item-icons button.is-active {
  background-color: var(--bs-primary);
  color: #fff;
}

.items-editor .item-content .ProseMirror {
  padding: .875rem;
  min-height: 160px;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  outline: none;
  box-shadow: none;
}

.items-editor .item-content .ProseMirror p {
  margin-bottom: 0;
}

.icon-rt-wrap {
  position: relative;
}

.icon-rt-wrap .icon-rt {
  position: absolute;
  right: 0;
  top: 0;
}

.fc-field-wrap {
  position: relative;
}

.fc-field-wrap .fc-field+.fc-field {
  margin-top: .75rem;
}

.fc-field-wrap .fc-field .icon-up-down {
  width: .8em;
  margin-top: .375rem;
  background-size: cover;
  margin-right: .25rem;
}

.fc-field-wrap .fc-field .icon-minus {
  width: .75rem;
  height: 2px;
  margin-top: -2px;
  background-color: var(--bs-primary);
  display: inline-block;
  vertical-align: middle;
  border-radius: 1px;
}

.cat-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.cat-list li {
  margin-bottom: .5rem;
}

.items-picture {
  width: 60px;
  height: 50px;
  border: 1px solid var(--bs-border-color);
  background-color: #EFEFEF;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}

.add-picture {
  position: relative;
  background-color: #fff;
  font-size: 18px;
}

.add-picture input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.items-picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/*.label-drag-space .col-form-label{ padding-left: 1.5rem;}*/
.icon-drag-wrap {
  position: relative;
  display: flex;
  align-items: center;
}

.icon-drag-wrap .icon-up-down {
  font-size: 1.125rem;
  width: .75rem;
  margin-right: .5rem;
  min-width: .75rem;
}

.fc-label-drag {
  position: relative;
}

.fc-check-drag {
  position: relative;
  padding-left: 1.5rem;
}

.fc-label-drag .icon-up-down {
  position: absolute;
  left: 0;
  top: .375rem;
  width: .75em;
}

.fc-check-drag .icon-up-down {
  position: absolute;
  left: 0;
  top: 1px;
  width: .75em;
  height: 1.125rem;
}

.fc-label-drag .icon-up-down+.row>.col-form-label {
  padding-left: 1.5rem;
}

/*.fc-label-drag .fc-label-drag-before{ padding-left: 1.5rem;}*/
.drag-list-wrap>div[style="position: relative;"]>div {
  z-index: inherit !important;
}

.dragHandle {
  cursor: move;
}

.items-code {
  background-color: var(--bs-body-bg);
  border: 1px solid #F2F2F2;
  padding: 1rem;
}

code {
  font-size: 1em;
  padding: 4px;
  background-color: #fff5f6;
}

.notification-menu .MuiPaper-root {
  padding-top: 37px;
  padding-bottom: 1px;
  max-height: auto !important;
  overflow-y: visible !important;
}

.notification-menu .MuiPaper-root:before {
  content: attr(data-title);
  background-color: var(--bs-body-bg);
  font-size: .875rem;
  font-weight: 600;
  font-family: var(--bs-body-font-family);
  padding: .5rem .875rem;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 2;
  border-radius: 3px 3px 0 0;
}

.notification-menu .MuiList-root {
  padding: 0;
  font-family: var(--bs-body-font-family);
  max-height: 304px;
  overflow-x: hidden;
  overflow-y: auto;
}

.notification-menu .MuiList-root .notification-items {
  padding: .75rem .875rem;
  font-family: var(--bs-body-font-family);
  flex-flow: column;
  align-items: flex-start;
  white-space: normal;
}

.notification-menu .MuiList-root .notification-items:hover {
  background-color: transparent;
}

.notification-menu .MuiList-root .notification-items+.notification-items {
  border-top: 1px solid var(--bs-border-color);
}

.notification-menu .MuiList-root .notification-items.unread {
  background-color: rgba(var(--bs-secondary-rgb), .05);
}

.notification-items .item-wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.notification-items .item-name {
  font-size: .875rem;
  flex: 1 1 auto;
}

.notification-items .item-time {
  font-size: .75rem;
  color: var(--bs-gray-500);
  white-space: nowrap;
}

.notification-items .item-text {
  font-size: .75rem;
  color: var(--bs-gray-700);
  width: 100%;
}

.notification-items .item-wrap+.item-text {
  margin-top: .25rem;
}

.offer-preview {
  background-color: #fff;
}

.offer-preview .item-header {
  padding: 1.625rem 1.625rem 1.625rem 2.5rem;
  border-bottom: 1px solid var(--bs-border-color);
}

.offer-preview .item-header .item-title {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 500;
  color: #000;
}

.offer-preview .item-header .item-date {
  font-size: .8rem;
}

.offer-preview .item-header .item-number {
  font-size: .8rem;
  font-weight: 500;
  margin-top: .375rem;
}

.offer-preview .item-body {
  padding: 1.625rem 2.125rem 2.5rem 2.5rem;
}

.offer-preview .item-body .address-wrap.bordered {
  padding: 1.625rem 2.125rem .5rem 2.5rem;
}

.offer-preview .item-body .address-wrap.bordered+.address-wrap.bordered {
  border-top: 1px solid var(--bs-border-color);
}

.items-info {
  margin-bottom: 1.5rem;
  line-height: 1.125rem;
}

.items-info p {
  margin-bottom: .5rem;
  font-size: .8rem;
}

.items-address {
  margin-bottom: 1.5rem;
  line-height: 1.5rem;
}

.items-info .item-title,
.items-address .item-title {
  font-weight: 500;
  padding-bottom: .5rem;
}

.items-address p {
  margin-bottom: 0;
  font-size: .75rem;
}

.items-table-wrap {
  padding-top: 1.5rem;
}

.items-table-wrap .item-title {
  font-weight: 500;
  padding-bottom: .5rem;
  border-bottom: 3px solid var(--bs-secondary);
  margin-bottom: .75rem;
}

.items-table-wrap .item-title-black-border {
  font-weight: 500;
  padding-bottom: .5rem;
  border-bottom: 2px solid var(--bs-primary);
  margin-bottom: .75rem;
}

.items-table-wrap .item-text {
  margin-bottom: 1rem;
  padding-top: .25rem;
}

.offer-preview .item-footer {
  padding: 1.25rem 1.625rem 1.25rem 2.5rem;
  border-top: 1px solid var(--bs-border-color);
  border-bottom: 1.625rem solid var(--bs-secondary);
  font-size: .75rem;
  line-height: 1.5rem;
}

.page-full-centered {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*.fc-v-event { width: 7px !important; padding-right: -20px !important; }
.fc-timegrid-event { border: none !important; }
.page-day-report .fc .fc-timegrid-event-harness { padding: 0 !important; width: 0px !important; }
.fc-event-time, .fc-event-title-container { display: none; }*/
/*.fc {
  --fc-button-text-color: #fff;
  --fc-button-bg-color: var(--bs-primary);
  --fc-button-border-color: var(--bs-primary);
  --fc-button-hover-bg-color: var(--bs-primary-hover);
  --fc-button-hover-border-color: var(--bs-primary-hover);
  --fc-button-active-bg-color: var(--bs-primary-hover);
  --fc-button-active-border-color: var(--bs-primary-hover);
}*/
.fc {
  --fc-button-text-color: var(--bs-primary);
  --fc-button-bg-color: #fff;
  --fc-button-border-color: #fff;
  --fc-button-hover-bg-color: #fff;
  --fc-button-hover-border-color: #fff;
  --fc-button-active-bg-color: #fff;
  --fc-button-active-border-color: #fff;
}

.page-day-report .fc .fc-button-primary:focus,
.page-day-report .fc .fc-button-primary:not(:disabled):active:focus,
.page-day-report .fc .fc-button-primary:not(:disabled).fc-button-active:focus {
  box-shadow: none;
}

.page-day-report .fc .fc-button-primary:disabled {
  color: #fff;
  background-color: var(--bs-primary-disabled);
  border-color: var(--bs-primary-disabled);
  opacity: 1;
}

.page-day-report .fc .fc-toolbar-title {
  font-size: 1.375rem;
  line-height: 1.625rem;
  font-weight: 500;
}

.page-day-report .fc .fc-toolbar.fc-header-toolbar {
  padding: .75rem;
  margin-bottom: 0;
}

.page-day-report .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk {
  display: flex;
  align-items: center;
}

.page-day-report .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk .fc-button {
  padding: 0;
}

.page-day-report .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk .fc-settingIcon-button {
  line-height: .75rem;
}

.page-day-report .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk .fc-icon {
  font-size: 1.25rem;
  vertical-align: top;
}

.page-day-report .fc .fc-toolbar.fc-header-toolbar>*> :not(:first-child) {
  margin-left: 1rem;
}

.page-day-report .fc-theme-standard th,
.page-day-report .fc-theme-standard td {
  border-color: #eee;
}

.page-day-report .fc .fc-timegrid-slot-minor {
  border-top-color: transparent;
}

.page-day-report .fc .fc-timegrid-now-indicator-arrow {
  display: none;
}

.page-day-report .fc .fc-timegrid-now-indicator-line {
  border-color: var(--bs-secondary);
  border-style: dashed;
}

.page-day-report .fc .fc-timegrid-col.fc-day-today {
  background-color: transparent;
  /* width: 55px; */
}

.page-day-report .fc th.fc-timegrid-axis,
.page-day-report .fc th.fc-col-header-cell {
  border-bottom-width: 2px;
}

.page-day-report .fc th.fc-col-header-cell .fc-scrollgrid-sync-inner {
  margin-bottom: -2px;
}

.page-day-report .fc th.fc-col-header-cell .fc-col-header-cell-cushion {
  padding: 0;
  width: 100%;
  font-size: .75rem;
  line-height: 1.25rem;
  font-weight: 400;
  text-align: left;
  border-bottom: 2px solid transparent;
}

.page-day-report .fc .fc-resourceTimeGridDay-view {

}

.page-day-report .fc .fc-resourceTimeGridDay-view th.fc-col-header-cell .fc-col-header-cell-cushion {
  border-bottom-color: var(--bs-secondary);
}

.page-day-report .fc th.fc-col-header-cell .fc-col-header-cell-cushion>.row {
  padding: .3rem;
  margin: 0;
  min-height: 90px;
}

.page-day-report .fc th.fc-day-today .fc-col-header-cell-cushion {
  background-color: #F8F8F8;
  border-bottom: 2px solid var(--bs-secondary);
}

.page-day-report .fc th .fc-col-header-cell-cushion .fc-day-red {
  background-color: var(--bs-secondary);
  border-bottom: 2px solid var(--bs-secondary);
  color: #fff;
}

.page-day-report .fc th .fc-col-header-cell-cushion .fc-day-black {
  background-color: var(--bs-primary);
  border-bottom: 2px solid var(--bs-secondary);
  color: #fff;
}

.page-day-report .fc th .fc-col-header-cell-cushion .fc-day-yellow {
  background-color: #F7A127;
  border-bottom: 2px solid var(--bs-secondary);
  color: #fff;
}

.page-day-report .fc th .fc-col-header-cell-cushion .fc-day-gray {
  background-color: #ADADAD;
  border-bottom: 2px solid var(--bs-secondary);
  color: #fff;
}

.page-day-report .fc th.fc-col-header-cell {
  vertical-align: bottom;
  height: 90px;
  /* width: 55px; */
}

.page-day-report .fc th.fc-col-header-cell .fc-today-text {
  display: none;
  font-size: .625rem;
  line-height: 1rem;
  font-weight: 500;
}

.page-day-report .fc th.fc-day-today .fc-today-text {
  display: block;
  font-size: .625rem;
  line-height: 1rem;
  font-weight: 500;
}

.page-day-report .fc th.fc-day-today .fc-header-date {
  font-weight: bold;
}

.page-day-report .fc .fc-timegrid-slot-label {
  vertical-align: top;
}

.page-day-report .fc .fc-timegrid-slot-label-frame {
  text-align: left;
  margin-top: -.6875rem;
}

.page-day-report .fc .fc-timegrid-slot-label-frame .fc-timegrid-slot-label-cushion {
  font-size: .8rem;
  font-weight: 500;
  color: #000;
  background-color: #fff;
}

.page-day-report .fc .fc-timegrid-slot {
  height: 2.375rem;
}

.page-day-report .fc .fc-timegrid-slots table tr:first-child td.fc-timegrid-slot-label:first-child .fc-timegrid-slot-label-frame {
  opacity: 0;
}

.page-day-report .fc .fc-timegrid-col-events {
  margin: 0;
  height: 100%;
  display: flex;
  /*justify-content: space-between;*/
  width: 100%;
  overflow: hidden;
  padding: 0;
}

.page-day-report .fc .fc-timegrid-col-events+.fc-timegrid-col-events {
  height: auto;
}

.page-day-report .fc .fc-timegrid-event-harness {
  /*position: relative;
  min-width: 100%;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;*/
  padding: 0 .75rem;
}

.page-day-report .fc .fc-timegrid-event-harness>.fc-timegrid-event {
  position: relative;
  width: 100%;
  height: 100%;
  border: none;
  font-size: .75rem;
  line-height: 1.125rem;
  font-weight: normal;
  border-radius: 3px;
}

.page-day-report .fc .fc-resourceTimeGridDay-view .fc-timegrid-event-harness>.fc-timegrid-event .fc-event-main {
  padding: .625rem .125rem;
}

.page-day-report .fc .fc-resourceTimeGridDay-view .fc-timegrid-event-harness>.fc-timegrid-event.fc-timegrid-event-short .fc-event-main {
  padding: .125rem;
}

.page-day-report .fc .fc-timeGridWeek-view .fc-timegrid-event-harness>.fc-timegrid-event .fc-event-main {
  padding: .375rem;
}

.page-day-report .fc .fc-timegrid-event-harness>.fc-timegrid-event .fc-event-main .fc-event-count {
  font-size: .875rem;
  line-height: 1.25rem;
  font-weight: bold;
}

.page-day-report .fc .fc-resourceTimeGridDay-view .fc-timegrid-event-harness>.fc-timegrid-event .fc-event-main .fc-event-count,
.page-day-report .fc .fc-timeGridWeek-view .fc-timegrid-event-harness>.fc-timegrid-event .fc-event-main .icon-size {
  display: none;
}

.page-day-report .fc .fc-timeGridWeek-view .fc-timegrid-event-harness {
  position: relative;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  min-width: 3rem;
  height: 2rem !important;
  padding: 0 .5rem;
  border-radius: 1rem;
}

.page-day-report .fc .fc-timeGridWeek-view .fc-timegrid-event-harness>.fc-timegrid-event {
  border-radius: 1rem;
}

.page-day-report .fc .fc-timeGridWeek-view .fc-timegrid-event-harness:hover {
  z-index: 30 !important;
}

/*.page-day-report .fc .fc-timeGridWeek-view .fc-timegrid-event-harness>.fc-timegrid-event .fc-event-main {
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 116px;
  height: auto;
  padding: .5rem;
  color: #fff;
  word-wrap: break-word;
  background-color: #000;
  border-radius: 3px;
  transition: all .3s ease;
}

.page-day-report .fc .fc-timeGridWeek-view .fc-timegrid-event-harness>.fc-timegrid-event:hover .fc-event-main {
  opacity: 1;
  overflow: visible;
  visibility: visible;
}*/

.page-day-report .fc .fc-timeGridWeek-view .fc-timegrid-event-harness>.fc-timegrid-event .fc-event-main .fc-event-main-frame {
  padding: .5rem;
}

.page-day-report .fc .fc-timegrid-event-harness>.fc-timegrid-event .fc-event-main .fc-event-main-frame .fc-event-title-container {
  flex-grow: 0;
  flex-shrink: 0;
  order: 1;
}

.page-day-report .fc .fc-timegrid-event-harness>.fc-timegrid-event .fc-event-main .fc-event-main-frame .fc-event-time {
  order: 2;
  margin-bottom: 0;
}

.btn-collapse-marking-delivery .fas{ transform: rotate(0deg); transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;}
.btn-collapse-marking-delivery.collapsed .fas{ transform: rotate(180deg); transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;}

.p-inside-m0 p {
  margin-bottom: .25rem;
}
.p-inside-m0 p:last-child {
  margin-bottom: 0;
}

.pagination-wbg {
  padding: .5rem;
  background-color: #fff;
  border-radius: .375rem;
}

.pdf-iframe,
.pdf-iframe iframe {
  width: 100%;
}
.pdf-iframe iframe {
  height: calc(100vh - 120px);
}

/* Responsive @media CSS */
@media (max-width: 3840px) {
  .inside-xxl-icon .input-group-icon {
    padding-right: 1.375rem;
  }

  .inside-xxl-icon .input-group-icon>.input-append-icon {
    right: 1px;
  }
}

@media (max-width: 1439.98px) {
  .inside-xl-icon .input-group-icon {
    padding-right: 1.375rem;
  }

  .inside-xl-icon .input-group-icon>.input-append-icon {
    right: 1px;
  }
}

@media (min-width: 768px) and (max-width: 1199.95px) {
  .sidebar-minimize .navbar-nav .nav-item.tip-active .nav-submenu {
    display: block;
    opacity: 1;
    padding: .25rem 0;
    min-width: 100px;
    background: var(--bs-primary);
    border-radius: 3px;
  }

  .sidebar-minimize .navbar-nav .nav-item.tip-active .nav-submenu li {
    margin-left: 0;
    padding: 0;
    line-height: 20px;
  }
}

@media (max-width: 1199.95px) {
  .sidebar:not(.sidebar-minimize) {
    padding-right: 0;
    box-shadow: 1px 0 3px rgba(0, 0, 0, 0.2);
  }

  .sidebar-toggle {
    margin-right: -.25rem;
  }

  .sidebar .tab-content {
    padding-right: 1.25rem;
  }

  .sidebar-minimize .tab-content {
    padding-right: 0;
  }

  .sidebar-overlay {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    z-index: 8;
  }

  .sidebar-overlay.active {
    opacity: 1;
    visibility: visible;
    overflow: visible;
  }

  .table .col-space {
    display: none;
  }

  .table .col-space-sm.lg-30 {
    width: 30%;
  }

  .table-wrap .col-space {
    display: none;
  }

  .inside-lg-icon .input-group-icon {
    padding-right: 1.375rem;
  }

  .inside-lg-icon .input-group-icon>.input-append-icon {
    right: 1px;
  }
}

@media (max-width: 991.98px) {
  .table td[width="70%"] {
    width: auto;
  }

  .inside-md-icon .input-group-icon {
    padding-right: 1.375rem;
  }

  .inside-md-icon .input-group-icon>.input-append-icon {
    right: 1px;
  }

  .table .col-space-sm.md-10 {
    width: 10%;
  }

  .page-day-report {
    overflow-x: auto;
    overflow-y: hidden;
  }

  .page-day-report>.fc {
    min-width: 980px;
  }
}

@media (max-width: 767px) {
  .header {
    min-height: 50px;
  }

  .header .navbar {
    min-height: 50px;
    left: 0;
    padding: 0;
  }

  .header .container-fluid {
    padding-left: 0;
    padding-right: .75rem;
  }

  .sidebar-toggle-btn-mobile {
    display: inline-block;
    padding: 1rem;
    line-height: 18px;
    background: transparent;
    border: none;
  }

  .sidebar-toggle-btn-mobile .navbar-toggler-icon {
    width: 18px;
    height: 18px;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='3' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    vertical-align: top;
  }

  .sidebar {
    top: 50px;
    left: -225px;
  }

  .sidebar-expand {
    left: 0;
  }

  .sidebar-toggle {
    display: none;
  }

  .sidebar-expand+.main,
  .main {
    padding-left: .75rem;
    padding-right: .75rem;
  }

  .page-header {
    padding: 1rem 0;
  }

  .search-bar {
    padding: .875rem 1rem;
  }

  .page-content.pc-rb {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .me-mb {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .page-content>.table-list {
    padding: .5rem 1rem 1rem;
  }

  .page-content .page-body {
    padding: 1rem;
  }

  .icon-rt-wrap {
    padding-top: .75rem;
  }

  .table-footer {
    margin-top: -.5rem;
    margin-left: -1rem;
  }

  .table-footer>* {
    margin-top: 1rem;
    margin-left: 1rem;
  }

  .inside-sm-icon .input-group-icon {
    padding-right: 1.375rem;
  }

  .inside-sm-icon .input-group-icon>.input-append-icon {
    right: 1px;
  }

  .table .col-space-sm {
    display: none;
  }

  .form-label-col-auto>.col-form-label {
    flex: 1 0 0%;
  }

  .form-label-col-auto>[class^="col-"]:not(.col-form-label) {
    width: auto;
  }
}

@media (max-width: 575px) {

  /*.table-footer .pagination{ width: 100%;}
  .table-footer > * + div.page-goto{ margin-top: 1.125rem;}*/
  .inside-xs-icon .input-group-icon {
    padding-right: 1.375rem;
  }

  .inside-xs-icon .input-group-icon>.input-append-icon {
    right: 1px;
  }

  .search-bar.add-icon-right {
    padding-right: 1rem;
  }

  .add-icon-right .add-icon-wrap {
    position: static;
    right: 0;
    bottom: 0;
    text-align: right;
    margin-top: .5rem;
  }

  .add-icon-xs-right .add-icon-wrap {
    position: absolute;
    right: 1rem;
    bottom: .875rem;
  }

  .search-bar .datepicker {
    width: 100%;
  }

  .items-bordered .item-label {
    padding-left: .5rem;
  }

  .MuiModal-root .MuiPaper-root {
    margin: 1rem;
    max-height: calc(100% - 2rem);
    width: calc(100% - 2rem);
  }

  .offer-preview .item-header {
    padding: 1rem;
  }

  .offer-preview .item-header .logo-xl {
    margin: 0 auto .5rem;
  }

  .offer-preview .item-header .item-date,
  .offer-preview .item-header .item-number {
    text-align: center;
  }

  .offer-preview .item-body {
    padding: 1rem;
  }

  .offer-preview .item-body .address-wrap.bordered {
    padding: 1rem 1rem .5rem;
  }

  .items-table-wrap {
    padding-top: .5rem;
  }

  .offer-preview .item-footer {
    padding: 1rem;
  }
}

@media (min-width: 1200px) {
  .sidebar-minimize .navbar-nav .nav-item:hover .nav-submenu {
    display: block;
    opacity: 1;
    padding: .25rem 0;
    min-width: 100px;
    background: var(--bs-primary);
    border-radius: 3px;
  }

  .sidebar-minimize .navbar-nav .nav-item:hover .nav-submenu li {
    margin-left: 0;
    padding: 0;
    line-height: 20px;
  }

  .main {
    padding-left: 203px;
  }

  .main>* {
    margin-left: 5px;
  }

  .table .col-space.xl-20 {
    width: 20%;
  }

  /*.page-day-report .fc .fc-timegrid-event-harness {
    min-width: 20%;
  }*/

  /*.page-day-report .fc .fc-timeGridWeek-view .fc-timegrid-event-harness {
    min-width: 16.66667%;
  }*/

  /*.page-day-report .fc .fc-timeGridWeek-view .fc-timegrid-event-harness:nth-child(1)>.fc-timegrid-event .fc-event-main {
    transform: translate(0, -50%);
  }

  .page-day-report .fc .fc-timeGridWeek-view .fc-timegrid-event-harness:nth-child(2)>.fc-timegrid-event .fc-event-main {
    transform: translate(-20%, -50%);
  }

  .page-day-report .fc .fc-timeGridWeek-view .fc-timegrid-event-harness:nth-child(5)>.fc-timegrid-event .fc-event-main {
    transform: translate(-80%, -50%);
  }

  .page-day-report .fc .fc-timeGridWeek-view .fc-timegrid-event-harness:nth-child(6)>.fc-timegrid-event .fc-event-main,
  .page-day-report .fc .fc-timeGridWeek-view .fc-timegrid-event-harness:not(:first-child):last-child>.fc-timegrid-event .fc-event-main {
    transform: translate(-100%, -50%);
  }*/
}

@media (min-width: 1440px) {
  .page-print .container {
    max-width: 1140px;
  }

  /*.page-day-report .fc .fc-timegrid-event-harness {
    min-width: 16.66667%;
  }*/

  /*.page-day-report .fc .fc-timeGridWeek-view .fc-timegrid-event-harness {
    min-width: 14.2857%;
  }*/

  /*.page-day-report .fc .fc-timeGridWeek-view .fc-timegrid-event-harness:nth-child(5)>.fc-timegrid-event .fc-event-main {
    transform: translate(-50%, -50%);
  }

  .page-day-report .fc .fc-timeGridWeek-view .fc-timegrid-event-harness:nth-child(6)>.fc-timegrid-event .fc-event-main {
    transform: translate(-80%, -50%);
  }

  .page-day-report .fc .fc-timeGridWeek-view .fc-timegrid-event-harness:nth-child(7)>.fc-timegrid-event .fc-event-main,
  .page-day-report .fc .fc-timeGridWeek-view .fc-timegrid-event-harness:not(:first-child):last-child>.fc-timegrid-event .fc-event-main {
    transform: translate(-100%, -50%);
  }*/
}

@media (min-width: 1600px) {
  /*.page-day-report .fc .fc-timegrid-event-harness {
    min-width: 14.2857%;
  }*/

  /*.page-day-report .fc .fc-timeGridWeek-view .fc-timegrid-event-harness {
    min-width: 12.5%;
  }*/

  /*.page-day-report .fc .fc-timeGridWeek-view .fc-timegrid-event-harness:nth-child(6)>.fc-timegrid-event .fc-event-main {
    transform: translate(-50%, -50%);
  }

  .page-day-report .fc .fc-timeGridWeek-view .fc-timegrid-event-harness:nth-child(7)>.fc-timegrid-event .fc-event-main {
    transform: translate(-80%, -50%);
  }

  .page-day-report .fc .fc-timeGridWeek-view .fc-timegrid-event-harness:nth-child(8)>.fc-timegrid-event .fc-event-main,
  .page-day-report .fc .fc-timeGridWeek-view .fc-timegrid-event-harness:not(:first-child):last-child>.fc-timegrid-event .fc-event-main {
    transform: translate(-100%, -50%);
  }*/
}

@media (min-width: 1920px) {
  /*.page-day-report .fc .fc-timegrid-event-harness {
    min-width: 12.5%;
  }*/

  /*.page-day-report .fc .fc-timeGridWeek-view .fc-timegrid-event-harness {
    min-width: 10%;
  }*/

  /*.page-day-report .fc .fc-timeGridWeek-view .fc-timegrid-event-harness:nth-child(7)>.fc-timegrid-event .fc-event-main,
  .page-day-report .fc .fc-timeGridWeek-view .fc-timegrid-event-harness:nth-child(8)>.fc-timegrid-event .fc-event-main {
    transform: translate(-50%, -50%);
  }

  .page-day-report .fc .fc-timeGridWeek-view .fc-timegrid-event-harness:nth-child(9)>.fc-timegrid-event .fc-event-main {
    transform: translate(-80%, -50%);
  }

  .page-day-report .fc .fc-timeGridWeek-view .fc-timegrid-event-harness:nth-child(10)>.fc-timegrid-event .fc-event-main,
  .page-day-report .fc .fc-timeGridWeek-view .fc-timegrid-event-harness:last-child:not(:first-child)>.fc-timegrid-event .fc-event-main {
    transform: translate(-100%, -50%);
  }*/
}

.page-print {
  padding: 1.5rem 0;
}

.page-print .page-content {
  box-shadow: 0px 1px 3px rgba(0, 0, 0, .16);
}

@media print {
  .print-none {
    display: none !important;
  }

  @page {
    margin: 26px 0 0;
  }

  body {
    background-color: #fff;
  }

  .page-print {
    padding: 0;
  }

  .page-print .container {
    max-width: 100%;
    padding: 0;
  }

  .page-print .page-content {
    background: #fff;
    border: none;
    box-shadow: none;
    border-radius: 0;
  }

  .offer-preview {
    display: table;
  }

  .offer-preview .item-header {
    padding-top: 0;
  }

  .offer-preview .item-fixed-bottom {
    display: table-footer-group;
    height: 150px;
  }

  .offer-preview .item-fixed-bottom .item-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
  }
}


/* SIGNATURE CANVAS STYLES  */
.sigCanvas {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background-color: #fff;
  border: 1px solid black
}

.sigImage {
  background-size: 200px 50px;
  width: 200px;
  height: 50px;
  background-color: white;
  border: 1px solid black;
  margin-bottom: 8px;
}

.unable-preview {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  color: red;
  font-size: 20px;
}