// @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// $theme-colors: (
//   "primary": #323b6b,
//   "success": #6bbc2e,
//   "secondary": #6d7fea,
//   "warning": #fdeb0f,
//   "info": #9ca6d6,
//   "danger": #f5475b
// );

$body-bg: #f8f8f8;
$body-color: #111;

// * {
//   font-family: "Poppins", sans-serif;
// }

// button {
//   color: white !important;
//   font-size: small !important;
//   padding: 10px 22px !important;
// }

.invalid-input {
  border: 1px solid red !important;
}

a {
  text-decoration: none !important;
}

.b-1 {
  border: 1px solid black;
}

.font-bold {
  font-weight: bold;
}

.cursor {
  cursor: pointer;
}

.btn-xs {
  padding: 4px 10px !important;
  font-size: 12px !important;
  line-height: 1.5 !important;
  border-radius: 3px !important;
}

.required-dot {
  color: red;
}

.number-as-textbox::-webkit-outer-spin-button,
.number-as-textbox::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: textfield;
}

body {
  overflow-y: scroll;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
}

.b1 {
  border: 1px solid black;
}

/*.fas {
  color: black;
}*/

// Import Bootstrap and its default variables
// @import "~bootstrap/scss/bootstrap.scss";